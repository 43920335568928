@import "../../../_typography.scss";
.abb-icon {
  &.abb-icon--huge {
    font-size: $icon-size-xl;
  }
  &.abb-icon--large {
    font-size: $icon-size-l;
  }
  &.abb-icon--medium {
    font-size: $icon-size-m;
  }
  &.abb-icon--small {
    font-size: $icon-size-s;
  }
  &.abb-icon--tiny {
    font-size: $icon-size-xs;
  }
  &.abb-icon--bold {
    font-weight: bold;
  }
  &.abb-icon--light {
    background-color: white;
  }
}
