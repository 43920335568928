@font-face {
  font-family: "ABB Font";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/ABBVoice/ABBvoice_W_Rg.eot"), url("../fonts/ABBVoice/ABBvoice_W_Rg.woff"),
    url("../fonts/ABBVoice/ABBvoice_W_Rg.woff2");
}

@font-face {
  font-family: "ABB Font";
  font-style: normal;
  font-weight: 300; /* lighter */
  src: url("../fonts/ABBVoice/ABBvoice_W_Lt.eot"), url("../fonts/ABBVoice/ABBvoice_W_Lt.woff"),
    url("../fonts/ABBVoice/ABBvoice_W_Lt.woff2");
}

@font-face {
  font-family: "ABB Font";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/ABBVoice/ABBvoice_W_Bd.eot"), url("../fonts/ABBVoice/ABBvoice_W_Bd.woff"),
    url("../fonts/ABBVoice/ABBvoice_W_Bd.woff2");
}

@font-face {
  font-family: "ABB Font";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/ABBVoice/ABBvoice_W_Md.eot"), url("../fonts/ABBVoice/ABBvoice_W_Md.woff"),
    url("../fonts/ABBVoice/ABBvoice_W_Md.woff2");
}
