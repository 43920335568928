@use "sass:math";

$element-size: 10px;
$element-offset: math.div($element-size, 2);

.resizable-component {
  position: absolute;
  user-select: none;
  touch-action: none;
  z-index: 110;

  &.resizable-component__side {
    $side-thickness: $element-size;
    $side-offset: $element-offset;
    $full-span: calc(#{100%} - #{$side-thickness});

    &.top-side {
      top: -$side-offset;
      left: $side-offset;
      width: $full-span;
      height: $side-thickness;
      cursor: n-resize;
    }

    &.bottom-side {
      bottom: -$side-offset;
      left: $side-offset;
      width: $full-span;
      height: $side-thickness;
      cursor: s-resize;
    }

    &.left-side {
      left: -$side-offset;
      top: $side-offset;
      width: $side-thickness;
      height: $full-span;
      cursor: w-resize;
    }

    &.right-side {
      right: -$side-offset;
      top: $side-offset;
      width: $side-thickness;
      height: $full-span;
      cursor: e-resize;
    }
  }

  &.resizable-component__corner {
    $corner-diameter: $element-size;
    $corner-offset: -1 * $element-offset;

    width: $corner-diameter;
    height: $corner-diameter;

    &.top-left {
      top: $corner-offset;
      left: $corner-offset;
      cursor: nw-resize;
    }
    &.top-right {
      top: $corner-offset;
      right: $corner-offset;
      cursor: ne-resize;
    }
    &.bottom-left {
      bottom: $corner-offset;
      left: $corner-offset;
      cursor: sw-resize;
    }
    &.bottom-right {
      bottom: $corner-offset;
      right: $corner-offset;
      cursor: se-resize;
    }
  }
}
