@import "./_colors.scss";
@import "../../../_typography.scss";

$input-editor-width: 100%;
$input-editor-height: 30px;
$input-editor-border-color: $gray-ba;
$input-editor-border-color--error: $red-ff;
$input-editor-equipment-height: 30px;

@mixin focus-style {
  outline: 1px solid var(--status-blue-color) !important;
  outline-offset: 1px !important;
}

@mixin focused-input {
  &:focus {
    @include focus-style();
  }
}

.editor--discreet-input {
  background-color: var(--input-background-primary);
  color: var(--foreground-primary);
  font-size: $input-font-size;
  border: 1px solid var(--input-border-color);
  padding: 6px;

  @include focused-input();
  &.invalid-input {
    border: 2px solid var(--error-message);
  }
}

.editor--focused {
  @include focus-style();
}

.editor--focused-nested {
  outline: 1px solid var(--status-blue-color) !important;
  outline-offset: -4px !important;
}

.editor--focused-rounded {
  @include focus-style();
  border-radius: 34px;
}

.editor--value-configured {
  font-weight: bold !important;
}
