// NOTE CSS variables for use in scss files.
// IF CHANGED UPDATE TYPESCRIPT FILE

$app-header-height: 52px;
$app-footer-height: 30px;
$app-side-bar-content-width: 288px;
$app-side-bar-equipment-property-width: 288px;
$initial-property-sheet-width: 280px;
$min-property-sheet-width: 280px;
$max-property-sheet-width-in-vw: 50vw;

$basic-card-header-height: 50px;
$basic-card-footer-height: 50px;

$tab-group-header-height: 49.5px;

$outline-width: 1px;
$inline-outline-width: 2px;

$app-filter-height: 60px;
$time-range-expanded-width: 660px;
$time-range-collapsed-width: 189px;
$root-path-expanded-width: 310px;
$root-path-collapsed-width: 149px;
$period-type-collapsed-width: 149px;
$period-type-filter-width: 40px;
$custom-filter-collapsed-width: 159px;
$custom-filter-width: 150px;
$filter-icon-item-width: 60px;
$initial-filter-width: 160px;
$filter-button-collapse: 24px;
$filter-pin-button-width: 35px;

$mobile-breakpoint: 480px;
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1366px;

$limit-tooltip-height: 24px;

$history-view-player-height: 75px;
$history-view-header-height: 52px;

$browser-search-input-height: 35px;

$date-time-picker-height: 340px;
$date-time-picker-width: 210px;

$pageVirtualGridGap: 10px;
