// TODO NG10 investigate if this is applied
.angular-tree-component {
  margin-left: 20px;
  width: fit-content;
}
.toggle-children::before {
  content: "\e98c";
}

.toggle-children {
  background-image: none;
  font-family: "abb-default-icons" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: $icon-size-xs;
  -webkit-font-smoothing: antialiased;
}
