$percentSize: 100%;
$cos45: 0.70710678118; // cos(45deg);

.status-indicator--no-shape {
  display: none;
}

// NOTE when circle is drawn it should be forced to be circular
.status-indicator--circle,
.status-indicator--oval {
  width: $percentSize;
  height: $percentSize;
  border-radius: 50%;
  display: inline-block;
}

.status-indicator--oval {
  height: $percentSize;
}

// NOTE when square is drawn it should be forced to be squarish
.status-indicator--rectangle,
.status-indicator--square {
  width: $percentSize;
  height: $percentSize;
  display: inline-block;
}

.status-indicator--rhombus {
  width: 50%;
  height: 50%;
  transform: rotate(67.5deg) skewX(45deg) scaleY($cos45);
  display: inline-block;
}

.status-indicator--bar {
  width: 12px;
  height: 24px;
  display: inline-block;
}

.status-indicator--ok {
  background-color: $abb-status-green;
}

.status-indicator--warn {
  background-color: $abb-status-yellow;
}

.status-indicator--error {
  background-color: $abb-status-red;
}

.status-indicator--bad {
  background-color: $light-gray-3;
  border: 1px solid $abb-status-red;
}

.status-indicator--off {
  background-color: $abb-status-magenta;
}

.status-indicator--undefined {
  background-color: $light-gray-3;
}
