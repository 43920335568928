/* You can add global styles to this file, and also import other style files */

// fonts
@import "./assets/ui-core/css/fonts.css";
@import "./assets/ui-core/css/abb-default-icons.scss";
@import "./assets/ui-core/css/abb-icons.scss";
@import "./assets/ui-core/css/dashboard-widgets.scss";
@import "./assets/ui-core/css/abb-km-icons.scss";
@import "./assets/ui-core/css/dashboard-icons.scss";

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "./assets/ui-core/css/_colors.scss";
@import "./assets/ui-core/css/status-indicators.scss";
@import "./assets/ui-core/css/buttons.scss";
@import "./assets/ui-core/css/resizable-component.scss";
@import "./assets/ui-core/css/checkboxes.scss";
@import "./assets/ui-core/css/dynamic-buttons.scss";
@import "./assets/ui-core/css/angular-tree.scss";
@import "./assets/ui-core/css/material-override.scss";
@import "./assets/ui-core/css/property-sheet.scss";
@import "./theme/material-custom.scss";
@import "./assets/ui-core/css/_effects";
@import "./assets/ui-core/css/calendar.scss";
@import "./assets/ui-core/css/icons.scss";
@import "./assets/ui-core/css/component.scss";
@import "./assets/ui-core/css/loading-indicator.scss";
@import "./assets/ui-core/css/base-editor.scss";
@import "./style-variables";
@import "./typography";
@import "material-design-icons/iconfont/material-icons.css";
@import "quill/dist/quill.snow.css";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "ABB Font";
}

body {
  font-family: "ABB Font";
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

input:disabled {
  /* NOTE required on iOS */
  -webkit-text-fill-color: #6d6d6d;
  opacity: 1;
}

.abb-input__icon--pull-left {
  margin-left: -20px;
}

.abb-input__icon {
  display: inline-block;
  vertical-align: middle;
  pointer-events: none;
  font-family: abb_icon_24;
  font-size: 16px;
  right: 5px;
  top: 5px;
}

.dragged-element-z-index {
  z-index: 9999 !important;
}

.container-component--edit-mode {
  @include shadow;
}

.edit-mode--active {
  outline: $gray-db solid $outline-width;

  &.component--selected {
    outline: $abb-ui-blue-60 solid $outline-width !important;
    outline-offset: -$outline-width;
  }

  &.basic-card--focused {
    outline-offset: 1px !important;
  }

  &.component--hovered,
  &.dragging-indicator {
    outline: $abb-ui-blue-60 dashed $outline-width;
  }

  &.component--highlighted {
    background-color: #ebf1ff;
  }
}

.type-title {
  display: none;
}

.edit-mode--active .type-title {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  opacity: 0.7;
}

.linked-element {
  cursor: pointer;
}

.element--hidden {
  display: none !important;
  background-color: transparent !important;
  & * {
    display: none !important;
  }
}

.element--half_transparent {
  opacity: 50%;
}

// hiding checkboxes in signal browser in Data Explorer bar
.configuratorSignalBrowser__hide-checkBoxes .mat-pseudo-checkbox {
  display: none !important;
}

webgui-core-outlet .mat-drawer-container.mat-sidenav-container {
  height: calc(100vh - #{$app-header-height});
}

.draged-element {
  position: absolute;
  z-index: 1;
  padding: 5px;
  background-color: #464646;
  color: #d2d2d2;
}

highcharts-chart,
.chart-container,
.card-item__drag-handle {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.simple-component {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.positioning-service__drag-overlay {
  // NOTE: left, top, width and height props are used to position
  // the drag overlay away from component's border which is used for resizing
  left: $element-offset;
  top: $element-offset;
  width: calc(#{100%} - #{$element-size});
  height: calc(#{100%} - #{$element-size});
  position: absolute;
  background: transparent;
  pointer-events: auto;
  cursor: grab;
  z-index: 111; // material paginator has index set to 100
  user-select: none;
}

.positioning-service__pick-widget {
  cursor: pointer;
}

.positioning-service__drag-overlay--no-drop {
  cursor: no-drop !important;
}

.floating-x-hidden {
  display: none;
}

.floating-x-show {
  display: inherit;
  cursor: pointer;
}

.breaking-text {
  word-break: break-all;
  // hyphens: auto;
  // overflow: hidden;
  white-space: normal;
}

@media screen and (max-width: 650px) {
  .control {
    margin-right: 4px !important;
  }
}

.browser__search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 70px;
}

.browser__search-input {
  border: 1px solid var(--input-border-color);
  background-color: var(--input-background-primary);
  color: var(--foreground-primary);
  display: flex;
  align-items: center;
  padding: 5px;
  box-sizing: border-box;
  min-width: 220px;
  height: $browser-search-input-height;
  border-radius: 6px;
}

.browser__search-input-text {
  border: none;
  background-color: transparent;
  width: 100%;
  font-size: $input-search-font-size;
  color: var(--foreground-primary);
  padding-right: 10px;
  box-sizing: border-box;

  &::placeholder {
    color: var(--foreground-secondary);
  }
}

.browser--visible-description {
  color: var(--status-blue-color) !important;
}

.selected {
  color: var(--status-blue-color);
}

.input--error {
  color: $abb-status-red;
  font-size: 10px;
}

.dialog__custom-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 10px 0 !important;

  button {
    width: 60px;
    border-radius: 5px;
    border: none;
  }
}

.selection-box {
  position: absolute;
  z-index: 10000;
  background-color: rgba($color: $abb-ui-blue-60, $alpha: 0.2);
  border: 1px solid rgba($abb-ui-blue-60, 0.5);
}

.unset-z-index {
  z-index: unset !important;
}

::-webkit-scrollbar {
  width: 0.6em;
  height: 0.6em;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.6rem;
  background-color: var(--scrollbar-color);
  border: 0.35rem solid transparent;
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 0.6rem;
  background-color: var(--scrollbar-color--hover);
}

::-webkit-scrollbar-corner {
  display: none;
}

.editor-item__message {
  font-size: $label-font-size;
  color: var(--error-message);
  margin-top: 5px;
  margin-left: 5px;
}

.component--inline-mode {
  cursor: text !important;
  outline: $highlighted solid $inline-outline-width !important;
  outline-offset: -$inline-outline-width;
}

.text-input--inline-mode {
  width: 100% !important;
  text-align: center !important;
  cursor: text !important;
}

.icon--inline-mode {
  padding: 0 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

//label and editor are in the same row
.editor__wrapper--inline {
  width: 70% !important;
}

.item-drag-dots {
  padding-right: 22px;
  box-sizing: border-box;
  width: 18px;
  color: var(--foreground-primary);
  display: flex;
  align-items: center;
  cursor: pointer;

  &:i {
    font-size: $icon-size-s;
  }
}

.link-button {
  color: $highlighted;
  cursor: pointer;
}

.com-icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: grayscale(100%);
}
