@import "../../../typography";
@font-face {
  font-family: "dashboard-widgets";
  src: url("../fonts/dashboard-widgets/dashboard-widgets.eot"),
    url("../fonts/dashboard-widgets/dashboard-widgets.ttf") format("truetype"),
    url("../fonts/dashboard-widgets/dashboard-widgets.woff") format("woff"),
    url("../fonts/dashboard-widgets/dashboard-widgets.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

.dashboard-widgets {
  font-family: "dashboard-widgets" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dashboard-widgets.icon-table:before {
  content: "\e900";
}
.dashboard-widgets.icon-oee-chart:before {
  content: "\e901";
}
.dashboard-widgets.icon-pareto-chart:before {
  content: "\e902";
}
.dashboard-widgets.icon-event-and-alarm-statistics-chart:before {
  content: "\e903";
}
.dashboard-widgets.icon-event-and-alarm-statistics-analysis-chart:before {
  content: "\e904";
}
.dashboard-widgets.icon-alarm-system-management-chart:before {
  content: "\e905";
}
.dashboard-widgets.icon-cluster-chart:before {
  content: "\e906";
}
.dashboard-widgets.icon-vertical-separator:before {
  content: "\e907";
}
.dashboard-widgets.icon-horizontal-separator:before {
  content: "\e908";
}
.dashboard-widgets.icon-label:before {
  content: "\e909";
}
.dashboard-widgets.icon-multiline-label:before {
  content: "\e90a";
}
.dashboard-widgets.icon-image:before {
  content: "\e90b";
}
.dashboard-widgets.icon-button:before {
  content: "\e90c";
}
.dashboard-widgets.icon-spider-chart:before {
  content: "\e90d";
}
.dashboard-widgets.icon-pie-charts:before {
  content: "\e90e";
}
.dashboard-widgets.icon-donut-charts:before {
  content: "\e90f";
}
.dashboard-widgets.icon-vertical-bars-1:before {
  content: "\e910";
}
.dashboard-widgets.icon-horizontal-bars-1:before {
  content: "\e911";
}
.dashboard-widgets.icon-line-chart:before {
  content: "\e912";
}
.dashboard-widgets.icon-area-chart:before {
  content: "\e913";
}
.dashboard-widgets.icon-vertical-bars:before {
  content: "\e914";
}
.dashboard-widgets.icon-horizontal-bars:before {
  content: "\e915";
}
.dashboard-widgets.icon-histogram:before {
  content: "\e916";
}
.dashboard-widgets.icon-scatter-chart:before {
  content: "\e917";
}
.dashboard-widgets.icon-trend:before {
  content: "\e918";
}
.dashboard-widgets.icon-binary-status-indicator:before {
  content: "\e919";
}
.dashboard-widgets.icon-multivalue-status-indicator:before {
  content: "\e91a";
}
.dashboard-widgets.icon-bullet-chart:before {
  content: "\e91b";
}
.dashboard-widgets.icon-dial-gauge:before {
  content: "\e91c";
}
.dashboard-widgets.icon-solid-gauge:before {
  content: "\e91d";
}
.dashboard-widgets.icon-horizontal-gauge:before {
  content: "\e91e";
}
.dashboard-widgets.icon-vertical-gauge:before {
  content: "\e91f";
}
.dashboard-widgets.icon-value:before {
  content: "\e920";
}
.dashboard-widgets.icon-value-in-circle:before {
  content: "\e921";
}
.dashboard-widgets.icon-value-and-trend:before {
  content: "\e922";
}
