/* You can add global styles to this file, and also import other style files */

// @import "~ui-core/styles.scss";
@import "../../rds/src/app/components/sidebar-navigation/styles/colors.scss";
@import "./mixins/spinner-styles.scss";
@import "./../../ui-core/src/assets/ui-core/css/colors";
@import "../../ui-core/src/style-variables.scss";
@import "../../ui-core/src/typography";

$spinner-size: 20px;
$spinner-color: $ui-grey-10;

.spinner {
  @include spinner-circle($spinner-size, $spinner-color);
  margin-left: 1rem;
}

.mat-mdc-dialog-container {
  min-width: 300px;
  color: var(--foreground-primary) !important;
}

.mat-drawer-container.mat-sidenav-container {
  height: 100vh;
}

.mat-dialog-action button {
  color: $ui-grey-10;
  background-color: $ui-blue-primary;
  border: 1px solid $ui-blue-primary;

  &:hover {
    background-color: $ui-blue-50;
    border-color: $ui-blue-50;
  }
  &:active {
    background-color: $ui-blue-40;
    border-color: $ui-blue-40;
  }
}

rds-fleet-list {
  height: initial !important;
}

.node-content-wrapper {
  padding: 0px 5px;

  &:hover {
    background-color: var(--background-secondary--active);
    color: var(--foreground-primary);
  }
}

dialog-content .mat-mdc-input-element {
  color: var(--foreground-secondary) !important;
}

.clientSearch_block .mat-mdc-input-element {
  color: var(--foreground-primary) !important;
}

.mat-expansion-panel {
  background-color: $abb-grey-3c !important;
  color: $abb-grey-40 !important;
}
.sidebar-navigation__container,
.mat-expansion-panel-header-title {
  color: #c6c6c6 !important;
}

.mat-expansion-panel-header[aria-disabled="true"] {
  .mat-expansion-panel-header-title strong {
    color: #c6c6c64a !important;
  }
}

.mat-expansion-panel-header,
.mat-expansion-panel-header-title {
  justify-content: space-between !important;
}

.mat-drawer-inner-container {
  background: $abb-grey-3c !important;
  overflow: hidden;
}

.mat-accordion .mat-expansion-panel {
  border-radius: 0px !important;
}

.mat-expansion-panel-body {
  height: 100%;
  width: 100%;
  background: $abb-grey-3c;
  padding: 0px !important;
  overflow: hidden;
}

.treeContainer,
tree-viewport,
.left-sidebar-nav,
.mat-expansion-panel-body,
.mat-mdc-autocomplete-panel {
  &::-webkit-scrollbar {
    width: 8px;
  }
}

.treeContainer,
.mat-expansion-panel-body,
.mat-mdc-autocomplete-panel {
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px $ui-grey-100-opacity-3;
    -webkit-box-shadow: inset 0 0 6px $ui-grey-100-opacity-3;
    background-color: $abb-grey-20;

    &:hover {
      background-color: $abb-grey-30;
    }
  }
}

.mat-mdc-optgroup-label {
  background-color: var(--input-background-primary);
  min-height: unset !important;

  .mdc-list-item__primary-text {
    font-size: $input-font-size !important;
    color: var(--foreground-primary) !important;
  }
}

.mat-active {
  background-color: $focus-background-dark !important;
  color: $ui-grey-20 !important;
}
.clientNameForm {
  display: block;
  width: 100%;
}
.mat-mdc-form-field {
  width: 100%;
}

.node_content_wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 35px;
  padding: 10px;
  font-size: $content-font-size;
}

.tree_expander {
  all: unset;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: var(--foreground-primary);
  cursor: pointer;
  margin: 0px 5px;
}

.report_rename_error.mat-mdc-form-field-error {
  font-size: 75% !important;
  margin-top: -15px;
}

.left-sidebar-nav {
  max-height: calc(100% - 60px);
  overflow-y: hidden;
}

.icon-orange {
  color: orange;
}

.icon-red {
  color: red;
}

@media screen and (max-width: 1000px) {
  #performance-mobile {
    .c-base-component {
      background-color: white !important;
      box-shadow: none !important;
    }
  }

  #dynamic-report {
    .c-base-component {
      background-color: white !important;
      box-shadow: none !important;

      .highcharts-root {
        margin-left: -12px;
      }

      .highcharts-axis-labels text {
        font-size: 15px !important;
      }

      .highcharts-xaxis-labels text {
        dominant-baseline: middle !important;
      }

      .highcharts-axis.highcharts-yaxis {
        font-size: 15px !important;
        text-anchor: middle !important;
      }

      .highcharts-series-2 path {
        stroke-width: 4 !important;
        stroke-dasharray: 6, 8 !important;
      }
    }
  }
}

.Copilot {
  width: 24px;
  height: 24px;
  background-color: var(--foreground-primary);
  mask: url("../src/assets/copilot.svg") no-repeat center;
  mask-size: contain;
  -webkit-mask: url("../src/assets/copilot.svg") no-repeat center;
  -webkit-mask-size: contain;
}

.Trendex {
  width: 24px;
  height: 24px;
  background-color: var(--foreground-primary);
  mask: url("../src/assets/trendex.svg") no-repeat center;
  mask-size: contain;
  -webkit-mask: url("../src/assets/trendex.svg") no-repeat center;
  -webkit-mask-size: contain;
}
