$icon-size-xl: 40px;
$icon-size-l: 30px;
$icon-size-m: 24px;
$icon-size-s: 15px;
$icon-size-xs: 10px;

$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-32: 32px;
$font-size-40: 40px;

$headline-font-size: $font-size-24;
$title-font-size: $font-size-20;
$subcategory-font-size: $font-size-16;
$subtitle-font-size: $font-size-14;
$section-title-font-size: $font-size-14;
$content-font-size: $font-size-14;
$subcontent-font-size: $font-size-12;
$input-font-size: $font-size-12;
$input-search-font-size: $font-size-14;
$label-font-size: $font-size-12;
$mat-calendar-content-size: $font-size-12;

$card-title-font-size: $font-size-20;
$mobile-card-title-font-size: $font-size-16;
$component-title-font-size: $font-size-16;
$tab-title-font-size: $font-size-14;
$table-font-size: $font-size-14;
