@import "../../../typography.scss";
@font-face {
  font-family: "abb-default-icons";
  src: url("../fonts/abb-default-icons/abb-default-icons.eot?7bvlog");
  src: url("../fonts/abb-default-icons/abb-default-icons.eot?7bvlog#iefix")
      format("embedded-opentype"),
    url("../fonts/abb-default-icons/abb-default-icons.ttf?7bvlog") format("truetype"),
    url("../fonts/abb-default-icons/abb-default-icons.woff?7bvlog") format("woff"),
    url("../fonts/abb-default-icons/abb-default-icons.svg?7bvlog#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

.abb-icon-default {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "abb-default-icons" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: $icon-size-s;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.abb-icon-default.Add_user:before {
  content: "\e900";
}
.abb-icon-default.Alarm_bell:before {
  content: "\e901";
}
.abb-icon-default.Alarm_bell_2:before {
  content: "\e902";
}
.abb-icon-default.Align_bottom:before {
  content: "\e903";
}
.abb-icon-default.Align_horizontal_center:before {
  content: "\e904";
}
.abb-icon-default.Align_left:before {
  content: "\e905";
}
.abb-icon-default.Align_right:before {
  content: "\e906";
}
.abb-icon-default.Align_top:before {
  content: "\e907";
}
.abb-icon-default.Align_vertical_center:before {
  content: "\e908";
}
.abb-icon-default.Analyze_document:before {
  content: "\e909";
}
.abb-icon-default.Applications:before {
  content: "\e90a";
}
.abb-icon-default.Audio_off:before {
  content: "\e90b";
}
.abb-icon-default.Audio_on:before {
  content: "\e90c";
}
.abb-icon-default.Bar_chart:before {
  content: "\e90d";
}
.abb-icon-default.Battery_charging:before {
  content: "\e90e";
}
.abb-icon-default.Battery_empty:before {
  content: "\e90f";
}
.abb-icon-default.Battery_full:before {
  content: "\e910";
}
.abb-icon-default.Battery_half:before {
  content: "\e911";
}
.abb-icon-default.Battery_high:before {
  content: "\e912";
}
.abb-icon-default.Battery_low:before {
  content: "\e913";
}
.abb-icon-default.Binary_data:before {
  content: "\e914";
}
.abb-icon-default.Bluetooth:before {
  content: "\e915";
}
.abb-icon-default.Briefcase:before {
  content: "\e916";
}
.abb-icon-default.Bring_to_front:before {
  content: "\e917";
}
.abb-icon-default.Broadcast:before {
  content: "\e918";
}
.abb-icon-default.Calculator:before {
  content: "\e919";
}
.abb-icon-default.Calendar:before {
  content: "\e91a";
}
.abb-icon-default.Call:before {
  content: "\e91b";
}
.abb-icon-default.Camera:before {
  content: "\e91c";
}
.abb-icon-default.Cart:before {
  content: "\e91d";
}
.abb-icon-default.Certificate_document_1:before {
  content: "\e91e";
}
.abb-icon-default.Certificate_document_2:before {
  content: "\e91f";
}
.abb-icon-default.Charging:before {
  content: "\e920";
}
.abb-icon-default.Chat_1:before {
  content: "\e921";
}
.abb-icon-default.Chat_2:before {
  content: "\e922";
}
.abb-icon-default.Check_mark:before {
  content: "\e923";
}
.abb-icon-default.Check_mark_circle_1:before {
  content: "\e924";
}
.abb-icon-default.Check_mark_circle_2:before {
  content: "\e925";
}
.abb-icon-default.Close:before {
  content: "\e926";
}
.abb-icon-default.Comment_1:before {
  content: "\e927";
}
.abb-icon-default.Comment_2:before {
  content: "\e928";
}
.abb-icon-default.Computer:before {
  content: "\e929";
}
.abb-icon-default.Control_and_settings:before {
  content: "\e92a";
}
.abb-icon-default.Copy:before {
  content: "\e92b";
}
.abb-icon-default.Cut:before {
  content: "\e92c";
}
.abb-icon-default.Dashboard_1:before {
  content: "\e92d";
}
.abb-icon-default.Dashboard_2:before {
  content: "\e92e";
}
.abb-icon-default.Database_Database:before {
  content: "\e92f";
}
.abb-icon-default.Database_new:before {
  content: "\e930";
}
.abb-icon-default.Devices:before {
  content: "\e931";
}
.abb-icon-default.Distribution:before {
  content: "\e932";
}
.abb-icon-default.Document:before {
  content: "\e933";
}
.abb-icon-default.Double_downn:before {
  content: "\e934";
}
.abb-icon-default.Double_left:before {
  content: "\e935";
}
.abb-icon-default.Double_right:before {
  content: "\e936";
}
.abb-icon-default.Double_up:before {
  content: "\e937";
}
.abb-icon-default.Down:before {
  content: "\e938";
}
.abb-icon-default.Down_arrow:before {
  content: "\e939";
}
.abb-icon-default.Download:before {
  content: "\e93a";
}
.abb-icon-default.Drive:before {
  content: "\e93b";
}
.abb-icon-default.Edit:before {
  content: "\e93c";
}
.abb-icon-default.End_call:before {
  content: "\e93d";
}
.abb-icon-default.Envelope:before {
  content: "\e93e";
}
.abb-icon-default.Error_circle_1:before {
  content: "\e93f";
}
.abb-icon-default.Error_circle_2:before {
  content: "\e940";
}
.abb-icon-default.Event:before {
  content: "\e941";
}
.abb-icon-default.Export:before {
  content: "\e942";
}
.abb-icon-default.Filter:before {
  content: "\e943";
}
.abb-icon-default.Folder:before {
  content: "\e944";
}
.abb-icon-default.Folder_new:before {
  content: "\e945";
}
.abb-icon-default.Folder_open:before {
  content: "\e946";
}
.abb-icon-default.Forward:before {
  content: "\e947";
}
.abb-icon-default.Fullscreen:before {
  content: "\e948";
}
.abb-icon-default.Gauge_1:before {
  content: "\e949";
}
.abb-icon-default.Gauge_2:before {
  content: "\e94a";
}
.abb-icon-default.Globe:before {
  content: "\e94b";
}
.abb-icon-default.Group:before {
  content: "\e94c";
}
.abb-icon-default.Help_circle_1:before {
  content: "\e94d";
}
.abb-icon-default.Help_circle_2:before {
  content: "\e94e";
}
.abb-icon-default.Hide:before {
  content: "\e94f";
}
.abb-icon-default.History:before {
  content: "\e950";
}
.abb-icon-default.Home:before {
  content: "\e951";
}
.abb-icon-default.Hourglass:before {
  content: "\e952";
}
.abb-icon-default.Image:before {
  content: "\e953";
}
.abb-icon-default.Import:before {
  content: "\e954";
}
.abb-icon-default.Information_circle_1:before {
  content: "\e955";
}
.abb-icon-default.Information_circle_2:before {
  content: "\e956";
}
.abb-icon-default.Key:before {
  content: "\e957";
}
.abb-icon-default.Label:before {
  content: "\e958";
}
.abb-icon-default.Left:before {
  content: "\e959";
}
.abb-icon-default.Left_arrow:before {
  content: "\e95a";
}
.abb-icon-default.Lightbulb:before {
  content: "\e95b";
}
.abb-icon-default.Link:before {
  content: "\e95c";
}
.abb-icon-default.List:before {
  content: "\e95d";
}
.abb-icon-default.Location_1:before {
  content: "\e95e";
}
.abb-icon-default.Location_2:before {
  content: "\e95f";
}
.abb-icon-default.Lock_closed:before {
  content: "\e960";
}
.abb-icon-default.Lock_open:before {
  content: "\e961";
}
.abb-icon-default.Log_in:before {
  content: "\e962";
}
.abb-icon-default.Log_out:before {
  content: "\e963";
}
.abb-icon-default.Magnet:before {
  content: "\e964";
}
.abb-icon-default.Make_same_height:before {
  content: "\e965";
}
.abb-icon-default.Make_same_size:before {
  content: "\e966";
}
.abb-icon-default.Make_same_widht:before {
  content: "\e967";
}
.abb-icon-default.Map:before {
  content: "\e968";
}
.abb-icon-default.Maximize:before {
  content: "\e969";
}
.abb-icon-default.Menu:before {
  content: "\e96a";
}
.abb-icon-default.Menu_narrow:before {
  content: "\e96b";
}
.abb-icon-default.Microphone:before {
  content: "\e96c";
}
.abb-icon-default.Microphone_off:before {
  content: "\e96d";
}
.abb-icon-default.Minimize:before {
  content: "\e96e";
}
.abb-icon-default.Minus:before {
  content: "\e96f";
}
.abb-icon-default.Minus_in_circle:before {
  content: "\e970";
}
.abb-icon-default.More:before {
  content: "\e971";
}
.abb-icon-default.Motor:before {
  content: "\e972";
}
.abb-icon-default.Motor_voltage:before {
  content: "\e973";
}
.abb-icon-default.Network:before {
  content: "\e974";
}
.abb-icon-default.New_document:before {
  content: "\e975";
}
.abb-icon-default.Numericals:before {
  content: "\e976";
}
.abb-icon-default.Open_in_new_window:before {
  content: "\e977";
}
.abb-icon-default.Panel:before {
  content: "\e978";
}
.abb-icon-default.Paperclip:before {
  content: "\e979";
}
.abb-icon-default.Paste:before {
  content: "\e97a";
}
.abb-icon-default.Pause:before {
  content: "\e97b";
}
.abb-icon-default.Piechart:before {
  content: "\e97c";
}
.abb-icon-default.Pin:before {
  content: "\e97d";
}
.abb-icon-default.Plant:before {
  content: "\e97e";
}
.abb-icon-default.Play:before {
  content: "\e97f";
}
.abb-icon-default.Plugin:before {
  content: "\e980";
}
.abb-icon-default.Plus:before {
  content: "\e981";
}
.abb-icon-default.Plus_in_circle:before {
  content: "\e982";
}
.abb-icon-default.Plus_in_circle_2:before {
  content: "\e983";
}
.abb-icon-default.Pop_out_window:before {
  content: "\e984";
}
.abb-icon-default.Power_on_off:before {
  content: "\e985";
}
.abb-icon-default.Print:before {
  content: "\e986";
}
.abb-icon-default.Redo:before {
  content: "\e987";
}
.abb-icon-default.Refresh:before {
  content: "\e988";
}
.abb-icon-default.Report:before {
  content: "\e989";
}
.abb-icon-default.Resistor:before {
  content: "\e98a";
}
.abb-icon-default.Rewind:before {
  content: "\e98b";
}
.abb-icon-default.Right:before {
  content: "\e98c";
}
.abb-icon-default.Right_arrow:before {
  content: "\e98d";
}
.abb-icon-default.Save:before {
  content: "\e98e";
}
.abb-icon-default.Save_as:before {
  content: "\e98f";
}
.abb-icon-default.Screen:before {
  content: "\e990";
}
.abb-icon-default.Search:before {
  content: "\e991";
}
.abb-icon-default.Send_to_back:before {
  content: "\e992";
}
.abb-icon-default.Server:before {
  content: "\e993";
}
.abb-icon-default.Setting:before {
  content: "\e994";
}
.abb-icon-default.Share:before {
  content: "\e995";
}
.abb-icon-default.Shortcut:before {
  content: "\e996";
}
.abb-icon-default.Skip_to_end:before {
  content: "\e997";
}
.abb-icon-default.Skip_to_star:before {
  content: "\e998";
}
.abb-icon-default.SLD_1:before {
  content: "\e999";
}
.abb-icon-default.SLD_2:before {
  content: "\e99a";
}
.abb-icon-default.Smartphone:before {
  content: "\e99b";
}
.abb-icon-default.Star:before {
  content: "\e99c";
}
.abb-icon-default.Stop:before {
  content: "\e99d";
}
.abb-icon-default.Synchronize:before {
  content: "\e99e";
}
.abb-icon-default.System:before {
  content: "\e99f";
}
.abb-icon-default.Table:before {
  content: "\e9a0";
}
.abb-icon-default.Template:before {
  content: "\e9a1";
}
.abb-icon-default.Template_new:before {
  content: "\e9a2";
}
.abb-icon-default.Test:before {
  content: "\e9a3";
}
.abb-icon-default.Time:before {
  content: "\e9a4";
}
.abb-icon-default.Trash:before {
  content: "\e9a5";
}
.abb-icon-default.Treeview:before {
  content: "\e9a6";
}
.abb-icon-default.Trend_1:before {
  content: "\e9a7";
}
.abb-icon-default.Trend_2:before {
  content: "\e9a8";
}
.abb-icon-default.Undo:before {
  content: "\e9a9";
}
.abb-icon-default.Unpin:before {
  content: "\e9aa";
}
.abb-icon-default.Up:before {
  content: "\e9ab";
}
.abb-icon-default.Up_arrow:before {
  content: "\e9ac";
}
.abb-icon-default.Upload:before {
  content: "\e9ad";
}
.abb-icon-default.USB:before {
  content: "\e9ae";
}
.abb-icon-default.Rearrange:before {
  content: "\e991";
}
.abb-icon-default.User:before {
  content: "\e9af";
}
.abb-icon-default.User_in_circle_User:before {
  content: "\e9b0";
}
.abb-icon-default.View:before {
  content: "\e9b1";
}
.abb-icon-default.Voltage:before {
  content: "\e9b2";
}
.abb-icon-default.Warning_circle_1:before {
  content: "\e9b3";
}
.abb-icon-default.Warning_circle_2:before {
  content: "\e9b4";
}
.abb-icon-default.Warning_triangle_1:before {
  content: "\e9b5";
}
.abb-icon-default.Warning_triangle_2:before {
  content: "\e9b6";
}
.abb-icon-default.Wrench:before {
  content: "\e9b7";
}
.abb-icon-default.Zoom_in:before {
  content: "\e9b8";
}
.abb-icon-default.Zoom_out:before {
  content: "\e9b9";
}
