.light-theme {
  --primary-black-button-background: black;
  --primary-black-button--hover: #3b3b3b;
  --primary-black-button--pressed: #474747;
  --primary-black-button--active: #4c85ff86;
  --primary-black-button-foreground: white;

  --background-primary: #f3f2f1;
  --background-primary--active: #e6e6e6;
  --background-secondary: #e6e6e6;
  --background-secondary--active: #dcdcdc;
  --background-tertiary: #dcdcdc;
  --background-quaternary: #fafafa;
  --background-quaternary--active: #dfdfdf;

  --foreground-primary: #1f1f1f;
  --foreground-primary--hover: black;
  --foreground-primary--disabled: #7b7b7b86;
  --foreground-primary--readonly: #464646;
  --foreground-secondary: #363636;
  --foreground-secondary--hover: #1f1f1f;
  --foreground-secondary--disabled: #54545486;

  --input-background-primary: white;
  --input-background-secondary: #c4c4c4;
  --input-border-color: #b8b8b8;
  --input--placeholder: #d1d1d1;

  --scrollbar-color: #cacaca;
  --scrollbar-color--hover: #b3b3b3;

  --status-blue-color: #3366ff;

  --border-color: #b8b8b8;

  --error-message: #ef3934;
}

.dark-theme {
  --primary-black-button-background: #fafafa;
  --primary-black-button--hover: #e6e6e6;
  --primary-black-button--pressed: #d1d1d1;
  --primary-black-button--active: #ebf1ff;
  --primary-black-button-foreground: #1f1f1f;

  --background-primary: #2c2c2c;
  --background-primary--active: #3c3c3c;
  --background-secondary: #3c3c3c;
  --background-secondary--active: #4c4c4c;
  --background-tertiary: #4c4c4c;
  --background-quaternary: #545454;
  --background-quaternary--active: #646464;

  --foreground-primary: #e1e1e1;
  --foreground-primary--hover: white;
  --foreground-primary--disabled: #dbdbdb86;
  --foreground-primary--readonly: #6c6c6c;
  --foreground-secondary: #c8c8c8;
  --foreground-secondary--hover: #e1e1e1;
  --foreground-secondary--disabled: #b8b8b886;

  --input-background-primary: #4c4c4c;
  --input-background-secondary: #696969;
  --input-border-color: #949494;
  --input--placeholder: #9e9e9e;

  --scrollbar-color: #737373;
  --scrollbar-color--hover: #8a8a8a;

  --status-blue-color: #4c85ff;

  --border-color: #2c2c2c;

  --error-message: #f56e6a;
}

.light-theme--template-builder {
  --primary-black-button-background: black;
  --primary-black-button--hover: #3b3b3b;
  --primary-black-button--pressed: #474747;
  --primary-black-button--active: #4c85ff86;
  --primary-black-button-foreground: white;

  --background-primary: white;
  --background-primary--active: #e6e6e6;
  --background-secondary: white;
  --background-secondary--active: #dcdcdc;
  --background-tertiary: #dcdcdc;
  --background-quaternary: #fafafa;
  --background-quaternary--active: #dfdfdf;

  --foreground-primary: #1f1f1f;
  --foreground-primary--hover: black;
  --foreground-primary--disabled: #7b7b7b86;
  --foreground-primary--readonly: #464646;
  --foreground-secondary: #363636;
  --foreground-secondary--hover: #1f1f1f;
  --foreground-secondary--disabled: #54545486;

  --input-background-primary: white;
  --input-background-secondary: #c4c4c4;
  --input-border-color: #b8b8b8;
  --input--placeholder: #d1d1d1;

  --scrollbar-color: #cacaca;
  --scrollbar-color--hover: #b3b3b3;

  --status-blue-color: #3366ff;

  --border-color: #b8b8b8;

  --error-message: #ef3934;
}

.dark-theme--template-builder {
  --primary-black-button-background: #fafafa;
  --primary-black-button--hover: #e6e6e6;
  --primary-black-button--pressed: #d1d1d1;
  --primary-black-button--active: #ebf1ff;
  --primary-black-button-foreground: #1f1f1f;

  --background-primary: #333333;
  --background-primary--active: #3c3c3c;
  --background-secondary: #333333;
  --background-secondary--active: #4c4c4c;
  --background-tertiary: #4c4c4c;
  --background-quaternary: #545454;
  --background-quaternary--active: #646464;

  --foreground-primary: #e1e1e1;
  --foreground-primary--hover: white;
  --foreground-primary--disabled: #dbdbdb86;
  --foreground-primary--readonly: #6c6c6c;
  --foreground-secondary: #c8c8c8;
  --foreground-secondary--hover: #e1e1e1;
  --foreground-secondary--disabled: #b8b8b886;

  --input-background-primary: #4c4c4c;
  --input-background-secondary: #696969;
  --input-border-color: #949494;
  --input--placeholder: #9e9e9e;

  --scrollbar-color: #737373;
  --scrollbar-color--hover: #8a8a8a;

  --status-blue-color: #4c85ff;

  --border-color: #2c2c2c;

  --error-message: #f56e6a;
}
