@font-face {
  font-family: "abb-km-icons";
  src: url("../fonts/abb-km-icons/abb-km-icons.eot?nwv837");
  src: url("../fonts/abb-km-icons/abb-km-icons.eot?nwv837#iefix") format("embedded-opentype"),
    url("../fonts/abb-km-icons/abb-km-icons.ttf?nwv837") format("truetype"),
    url("../fonts/abb-km-icons/abb-km-icons.woff?nwv837") format("woff"),
    url("../fonts/abb-km-icons/abb-km-icons.svg?nwv837#abb-km-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.abb-km-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "abb-km-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.abb-km-icon.Accreditation_definition:before {
  content: "\e900";
}
.abb-km-icon.Accreditation_definition_audit:before {
  content: "\e901";
}
.abb-km-icon.Authorization_status:before {
  content: "\e902";
}
.abb-km-icon.Auto_print_status:before {
  content: "\e903";
}
.abb-km-icon.Backup_log:before {
  content: "\e904";
}
.abb-km-icon.Calibration_report:before {
  content: "\e905";
}
.abb-km-icon.Chronogram:before {
  content: "\e906";
}
.abb-km-icon.Comment_log:before {
  content: "\e907";
}
.abb-km-icon.Cps_system_parameters:before {
  content: "\e908";
}
.abb-km-icon.Data_correction_form:before {
  content: "\e909";
}
.abb-km-icon.Data_correction_log:before {
  content: "\e90a";
}
.abb-km-icon.Default:before {
  content: "\e90b";
}
.abb-km-icon.Demand:before {
  content: "\e90c";
}
.abb-km-icon.Dispatch_channels_demand_split:before {
  content: "\e90d";
}
.abb-km-icon.Ebm_planning_data_download:before {
  content: "\e90e";
}
.abb-km-icon.Eem_planning_data_upload:before {
  content: "\e90f";
}
.abb-km-icon.Energy_tariff:before {
  content: "\e910";
}
.abb-km-icon.Equipment:before {
  content: "\e911";
}
.abb-km-icon.Equipment_material_parameters:before {
  content: "\e912";
}
.abb-km-icon.Equipment_type:before {
  content: "\e913";
}
.abb-km-icon.Erp_connector_various:before {
  content: "\e914";
}
.abb-km-icon.Error_log:before {
  content: "\e915";
}
.abb-km-icon.Event_alarm_list:before {
  content: "\e916";
}
.abb-km-icon.Event_alarm_statistic:before {
  content: "\e917";
}
.abb-km-icon.Fixed_production:before {
  content: "\e918";
}
.abb-km-icon.Goals:before {
  content: "\e919";
}
.abb-km-icon.Goals_admin:before {
  content: "\e91a";
}
.abb-km-icon.Holiday:before {
  content: "\e91b";
}
.abb-km-icon.Job_rebook:before {
  content: "\e91c";
}
.abb-km-icon.Log_reference:before {
  content: "\e91d";
}
.abb-km-icon.Maintenance_forecast:before {
  content: "\e91e";
}
.abb-km-icon.Maintenance_plan:before {
  content: "\e91f";
}
.abb-km-icon.Material:before {
  content: "\e920";
}
.abb-km-icon.Material_changes:before {
  content: "\e921";
}
.abb-km-icon.Material_mapping:before {
  content: "\e922";
}
.abb-km-icon.Milled_additives_mapping:before {
  content: "\e923";
}
.abb-km-icon.Non_availability_plan:before {
  content: "\e924";
}
.abb-km-icon.Operation_report:before {
  content: "\e925";
}
.abb-km-icon.Operator_schedule:before {
  content: "\e926";
}
.abb-km-icon.Pareto_chart:before {
  content: "\e927";
}
.abb-km-icon.Power_generation_parameters:before {
  content: "\e928";
}
.abb-km-icon.Process_limits:before {
  content: "\e929";
}
.abb-km-icon.Publishing_log:before {
  content: "\e92a";
}
.abb-km-icon.Remote_server_status:before {
  content: "\e92b";
}
.abb-km-icon.Remotejob_jobsummary:before {
  content: "\e92c";
}
.abb-km-icon.Resource_schedule:before {
  content: "\e92d";
}
.abb-km-icon.Sample_details:before {
  content: "\e92e";
}
.abb-km-icon.Sample_limits:before {
  content: "\e92f";
}
.abb-km-icon.Sample_list:before {
  content: "\e930";
}
.abb-km-icon.Sample_rep:before {
  content: "\e931";
}
.abb-km-icon.Sample_work_list:before {
  content: "\e932";
}
.abb-km-icon.Sample_work_monitoring:before {
  content: "\e933";
}
.abb-km-icon.Scenario:before {
  content: "\e934";
}
.abb-km-icon.Scenario_comparison:before {
  content: "\e935";
}
.abb-km-icon.Scheduler:before {
  content: "\e936";
}
.abb-km-icon.Script:before {
  content: "\e937";
}
.abb-km-icon.Session_log:before {
  content: "\e938";
}
.abb-km-icon.Shift:before {
  content: "\e939";
}
.abb-km-icon.Signal_value:before {
  content: "\e93a";
}
.abb-km-icon.Staff:before {
  content: "\e93b";
}
.abb-km-icon.Stop_log:before {
  content: "\e93c";
}
.abb-km-icon.Stop_log_audit:before {
  content: "\e93d";
}
.abb-km-icon.Storage_capacity:before {
  content: "\e93e";
}
.abb-km-icon.Storage_location:before {
  content: "\e93f";
}
.abb-km-icon.Substance_lot:before {
  content: "\e940";
}
.abb-km-icon.Substance_type:before {
  content: "\e941";
}
.abb-km-icon.System_status:before {
  content: "\e942";
}
.abb-km-icon.Transport:before {
  content: "\e943";
}
.abb-km-icon.Transport_path:before {
  content: "\e944";
}
.abb-km-icon.Trend:before {
  content: "\e945";
}
.abb-km-icon.Trend_container:before {
  content: "\e946";
}
.abb-km-icon.Trend_spc:before {
  content: "\e947";
}
.abb-km-icon.Url_link:before {
  content: "\e948";
}
.abb-km-icon.Usage_dashboard:before {
  content: "\e949";
}
.abb-km-icon.Weekly_sales_template:before {
  content: "\e94a";
}
.abb-km-icon.Alarm-system-Management-chart-1:before {
  content: "\e94b";
}
.abb-km-icon.Alarm-system-Management-chart-2:before {
  content: "\e94c";
}
.abb-km-icon.Alarm-system-Management-chart-3:before {
  content: "\e94d";
}
.abb-km-icon.Alarm-system-Management-chart-4:before {
  content: "\e94e";
}
.abb-km-icon.KM-1:before {
  content: "\e94f";
}
.abb-km-icon.KM-2:before {
  content: "\e950";
}
.abb-km-icon.KM-3:before {
  content: "\e951";
}
.abb-km-icon.KM-5:before {
  content: "\e952";
}
.abb-km-icon.Spider-Chart-1:before {
  content: "\e953";
}
.abb-km-icon.Spider-Chart-2:before {
  content: "\e954";
}
.abb-km-icon.Spider-Chart-3:before {
  content: "\e955";
}
