@use "@angular/material" as mat;
// @import '@angular/material/theming';
// // Plus imports for other components in your app.
// // Include the common styles for Angular Material. We include this here so that you only
// // have to load a single css file for Angular Material in your app.
// // Be sure that you only ever include this mixin once!
// @include mat.core();
// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $km-pa-primary: mat.define-palette(mat.$blue-palette);
// $km-pa-accent: mat.define-palette(mat.$blue-palette, A200, A100, A400);
// // The warn palette is optional (defaults to red).
// $km-pa-warn: mat.define-palette(mat.$red-palette);
// // Create the theme object (a Sass map containing all of the palettes).
// $km-pa-theme: mat.define-light-theme($km-pa-primary, $km-pa-accent, $km-pa-warn);
// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include mat.all-component-themes(km-pa-theme);
// @import "@angular/material/theming";
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$km-pa-app-primary: mat.m2-define-palette(mat.$m2-blue-palette);
$km-pa-app-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
// The warn palette is optional (defaults to red).
$km-pa-app-warn: mat.m2-define-palette(mat.$m2-red-palette);
// Create the theme object (a Sass map containing all of the palettes).
$km-pa-app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $km-pa-app-primary,
      accent: $km-pa-app-accent,
      warn: $km-pa-app-warn
    ),
    typography: mat.m2-define-typography-config(),
    density: 0
  )
);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($km-pa-app-theme);

.asdffdsa {
  display: none;
}
