@import "../../../typography";
@font-face {
  font-family: "abb-icons";
  src: url("../fonts/abb-icons/abb-icons.eot?lel6dg");
  src: url("../fonts/abb-icons/abb-icons.eot?lel6dg#iefix") format("embedded-opentype"),
    url("../fonts/abb-icons/abb-icons.woff2?lel6dg") format("woff2"),
    url("../fonts/abb-icons/abb-icons.ttf?lel6dg") format("truetype"),
    url("../fonts/abb-icons/abb-icons.woff?lel6dg") format("woff"),
    url("../fonts/abb-icons/abb-icons.svg?lel6dg#ABB-UI-Icons-32x32") format("svg");
  font-weight: normal;
  font-style: normal;
}

.abb-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "abb-icons" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: $icon-size-s;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.abb-icon.Add_user:before {
  content: "\e900";
}
.abb-icon.Alarm_bell:before {
  content: "\e901";
}
.abb-icon.Align_bottom:before {
  content: "\e902";
}
.abb-icon.Align_horizontal_center:before {
  content: "\e903";
}
.abb-icon.Align_left:before {
  content: "\e904";
}
.abb-icon.Align_right:before {
  content: "\e905";
}
.abb-icon.Align_top:before {
  content: "\e906";
}
.abb-icon.Align_vertical_center:before {
  content: "\e907";
}
.abb-icon.Analyze_document:before {
  content: "\e908";
}
.abb-icon.Anchor:before {
  content: "\e909";
}
.abb-icon.Audio_off:before {
  content: "\e90a";
}
.abb-icon.Audio_on:before {
  content: "\e90b";
}
.abb-icon.Background:before {
  content: "\e90c";
}
.abb-icon.Bar_chart:before {
  content: "\e90d";
}
.abb-icon.Battery_charging:before {
  content: "\e90e";
}
.abb-icon.Battery_empty:before {
  content: "\e90f";
}
.abb-icon.Battery_full:before {
  content: "\e910";
}
.abb-icon.Battery_half:before {
  content: "\e911";
}
.abb-icon.Battery_high:before {
  content: "\e912";
}
.abb-icon.Battery_low:before {
  content: "\e913";
}
.abb-icon.Binary_data:before {
  content: "\e914";
}
.abb-icon.Bluetooth:before {
  content: "\e915";
}
.abb-icon.Boolean:before {
  content: "\e916";
}
.abb-icon.Briefcase:before {
  content: "\e917";
}
.abb-icon.Bring_to_front:before {
  content: "\e918";
}
.abb-icon.Broadcast:before {
  content: "\e919";
}
.abb-icon.Calculator:before {
  content: "\e91a";
}
.abb-icon.Calendar:before {
  content: "\e91b";
}
.abb-icon.Call:before {
  content: "\e91c";
}
.abb-icon.Camera:before {
  content: "\e91d";
}
.abb-icon.Cart:before {
  content: "\e91e";
}
.abb-icon.Certificate_document_1:before {
  content: "\e91f";
}
.abb-icon.Certificate_document_2:before {
  content: "\e920";
}
.abb-icon.Chat:before {
  content: "\e921";
}
.abb-icon.Check_mark:before {
  content: "\e922";
}
.abb-icon.Check_mark_circle_1:before {
  content: "\e923";
}
.abb-icon.Check_mark_circle_2:before {
  content: "\e924";
}
.abb-icon.Close:before {
  content: "\e925";
}
.abb-icon.Comment:before {
  content: "\e926";
}
.abb-icon.Computer:before {
  content: "\e927";
}
.abb-icon.Connect:before {
  content: "\e928";
}
.abb-icon.Controller_cabinet:before {
  content: "\e929";
}
.abb-icon.Controls_and_settings:before {
  content: "\e92a";
}
.abb-icon.Copy:before {
  content: "\e92b";
}
.abb-icon.Cut:before {
  content: "\e92c";
}
.abb-icon.Dashboard:before {
  content: "\e92d";
}
.abb-icon.Database:before {
  content: "\e92e";
}
.abb-icon.Database_new:before {
  content: "\e92f";
}
.abb-icon.Decimal:before {
  content: "\e930";
}
.abb-icon.Devices:before {
  content: "\e931";
}
.abb-icon.Disconnect:before {
  content: "\e932";
}
.abb-icon.Distribution:before {
  content: "\e933";
}
.abb-icon.Document:before {
  content: "\e934";
}
.abb-icon.Dollar:before {
  content: "\e935";
}
.abb-icon.Double_down:before {
  content: "\e936";
}
.abb-icon.Double_left:before {
  content: "\e937";
}
.abb-icon.Double_right:before {
  content: "\e938";
}
.abb-icon.Double_up:before {
  content: "\e939";
}
.abb-icon.Down:before {
  content: "\e93a";
}
.abb-icon.Down_arrow:before {
  content: "\e93b";
}
.abb-icon.Download:before {
  content: "\e93c";
}
.abb-icon.Drive:before {
  content: "\e93d";
}
.abb-icon.Edit:before {
  content: "\e93e";
}
.abb-icon.End_call:before {
  content: "\e93f";
}
.abb-icon.Envelope:before {
  content: "\e940";
}
.abb-icon.Error_circle_1:before {
  content: "\e941";
}
.abb-icon.Error_circle_2:before {
  content: "\e942";
}
.abb-icon.Euro:before {
  content: "\e943";
}
.abb-icon.Event:before {
  content: "\e944";
}
.abb-icon.Export:before {
  content: "\e945";
}
.abb-icon.Factory:before {
  content: "\e946";
}
.abb-icon.Filter:before {
  content: "\e947";
}
.abb-icon.Folder:before {
  content: "\e948";
}
.abb-icon.Folder_new:before {
  content: "\e949";
}
.abb-icon.Folder_open:before {
  content: "\e94a";
}
.abb-icon.Forward:before {
  content: "\e94b";
}
.abb-icon.Fullscreen:before {
  content: "\e94c";
}
.abb-icon.Gauge:before {
  content: "\e94d";
}
.abb-icon.Gbp:before {
  content: "\e94e";
}
.abb-icon.Globe:before {
  content: "\e94f";
}
.abb-icon.Group:before {
  content: "\e950";
}
.abb-icon.Help_circle_1:before {
  content: "\e951";
}
.abb-icon.Help_circle_2:before {
  content: "\e952";
}
.abb-icon.Hide:before {
  content: "\e953";
}
.abb-icon.History:before {
  content: "\e954";
}
.abb-icon.Home:before {
  content: "\e955";
}
.abb-icon.Hour_glass:before {
  content: "\e956";
}
.abb-icon.Image:before {
  content: "\e957";
}
.abb-icon.Import:before {
  content: "\e958";
}
.abb-icon.Information_circle_1:before {
  content: "\e959";
}
.abb-icon.Information_circle_2:before {
  content: "\e95a";
}
.abb-icon.Key:before {
  content: "\e95b";
}
.abb-icon.Label:before {
  content: "\e95c";
}
.abb-icon.Left:before {
  content: "\e95d";
}
.abb-icon.Left_arrow:before {
  content: "\e95e";
}
.abb-icon.Level:before {
  content: "\e95f";
}
.abb-icon.Light_bulb:before {
  content: "\e960";
}
.abb-icon.Link:before {
  content: "\e961";
}
.abb-icon.List:before {
  content: "\e962";
}
.abb-icon.Location:before {
  content: "\e963";
}
.abb-icon.Lock_closed:before {
  content: "\e964";
}
.abb-icon.Lock_open:before {
  content: "\e965";
}
.abb-icon.Log_in:before {
  content: "\e966";
}
.abb-icon.Log_out:before {
  content: "\e967";
}
.abb-icon.Magnet:before {
  content: "\e968";
}
.abb-icon.Make_same_height:before {
  content: "\e969";
}
.abb-icon.Make_same_size:before {
  content: "\e96a";
}
.abb-icon.Make_same_width:before {
  content: "\e96b";
}
.abb-icon.Map:before {
  content: "\e96c";
}
.abb-icon.Matrix:before {
  content: "\e96d";
}
.abb-icon.Maximize:before {
  content: "\e96e";
}
.abb-icon.Menu:before {
  content: "\e96f";
}
.abb-icon.Menu_narrow:before {
  content: "\e970";
}
.abb-icon.Microphone:before {
  content: "\e971";
}
.abb-icon.Microphone_off:before {
  content: "\e972";
}
.abb-icon.Minimize:before {
  content: "\e973";
}
.abb-icon.Minus:before {
  content: "\e974";
}
.abb-icon.Minus_in_circle:before {
  content: "\e975";
}
.abb-icon.Missed_call:before {
  content: "\e976";
}
.abb-icon.Moon:before {
  content: "\e977";
}
.abb-icon.More:before {
  content: "\e978";
}
.abb-icon.Motor:before {
  content: "\e979";
}
.abb-icon.Network:before {
  content: "\e97a";
}
.abb-icon.New_document:before {
  content: "\e97b";
}
.abb-icon.Numericals:before {
  content: "\e97c";
}
.abb-icon.Object:before {
  content: "\e97d";
}
.abb-icon.Object_container_1:before {
  content: "\e97e";
}
.abb-icon.Object_container_2:before {
  content: "\e97f";
}
.abb-icon.Open_in_new_window:before {
  content: "\e980";
}
.abb-icon.Panel:before {
  content: "\e981";
}
.abb-icon.Paper_clip:before {
  content: "\e982";
}
.abb-icon.Paste:before {
  content: "\e983";
}
.abb-icon.Pause:before {
  content: "\e984";
}
.abb-icon.Pie_chart:before {
  content: "\e985";
}
.abb-icon.Pin:before {
  content: "\e986";
}
.abb-icon.Play:before {
  content: "\e987";
}
.abb-icon.Plugin:before {
  content: "\e988";
}
.abb-icon.Plus:before {
  content: "\e989";
}
.abb-icon.Plus_in_circle:before {
  content: "\e98a";
}
.abb-icon.Pop_out_window:before {
  content: "\e98b";
}
.abb-icon.Power_on_off:before {
  content: "\e98c";
}
.abb-icon.Pressure:before {
  content: "\e98d";
}
.abb-icon.Print:before {
  content: "\e98e";
}
.abb-icon.Pump:before {
  content: "\e98f";
}
.abb-icon.Qr_code:before {
  content: "\e990";
}
.abb-icon.Rearrange:before {
  content: "\e991";
}
.abb-icon.Redo:before {
  content: "\e992";
}
.abb-icon.Refresh:before {
  content: "\e993";
}
.abb-icon.Reports:before {
  content: "\e994";
}
.abb-icon.Resistor:before {
  content: "\e995";
}
.abb-icon.Rewind:before {
  content: "\e996";
}
.abb-icon.Right:before {
  content: "\e997";
}
.abb-icon.Right_arrow:before {
  content: "\e998";
}
.abb-icon.Robot:before {
  content: "\e999";
}
.abb-icon.Robot_cabinet:before {
  content: "\e99a";
}
.abb-icon.Robot_tool:before {
  content: "\e99b";
}
.abb-icon.Rotate_left_90:before {
  content: "\e99c";
}
.abb-icon.Rotate_left_180:before {
  content: "\e99d";
}
.abb-icon.Rotate_right_270:before {
  content: "\e99e";
}
.abb-icon.Rotate_left_2701:before {
  content: "\e99f";
}
.abb-icon.Rotate_right_90:before {
  content: "\e9a0";
}
.abb-icon.Rotate_right_180:before {
  content: "\e9a1";
}
.abb-icon.Save:before {
  content: "\e9a2";
}
.abb-icon.Save_as:before {
  content: "\e9a3";
}
.abb-icon.Screen:before {
  content: "\e9a4";
}
.abb-icon.Search:before {
  content: "\e9a5";
}
.abb-icon.Send_to_back:before {
  content: "\e9a6";
}
.abb-icon.Server:before {
  content: "\e9a7";
}
.abb-icon.Settings:before {
  content: "\e9a8";
}
.abb-icon.Share:before {
  content: "\e9a9";
}
.abb-icon.Shortcut:before {
  content: "\e9aa";
}
.abb-icon.Shuffle:before {
  content: "\e9ab";
}
.abb-icon.Skip_to_end:before {
  content: "\e9ac";
}
.abb-icon.Skip_to_start:before {
  content: "\e9ad";
}
.abb-icon.Sld_1:before {
  content: "\e9ae";
}
.abb-icon.Sld_2:before {
  content: "\e9af";
}
.abb-icon.Smartphone:before {
  content: "\e9b0";
}
.abb-icon.Sort_alphabet_down:before {
  content: "\e9b1";
}
.abb-icon.Sort_alphabet_up:before {
  content: "\e9b2";
}
.abb-icon.Sort_date_down_1:before {
  content: "\e9b3";
}
.abb-icon.Sort_date_down_2:before {
  content: "\e9b4";
}
.abb-icon.Sort_date_up_1:before {
  content: "\e9b5";
}
.abb-icon.Sort_date_up_2:before {
  content: "\e9b6";
}
.abb-icon.Specification:before {
  content: "\e9b7";
}
.abb-icon.Stairs:before {
  content: "\e9b8";
}
.abb-icon.Star:before {
  content: "\e9b9";
}
.abb-icon.Stop:before {
  content: "\e9ba";
}
.abb-icon.Sun:before {
  content: "\e9bb";
}
.abb-icon.Sun_2:before {
  content: "\e9bc";
}
.abb-icon.Synchronize:before {
  content: "\e9bd";
}
.abb-icon.System:before {
  content: "\e9be";
}
.abb-icon.Table:before {
  content: "\e9bf";
}
.abb-icon.Temperature:before {
  content: "\e9c0";
}
.abb-icon.Temperature_celsius_1:before {
  content: "\e9c1";
}
.abb-icon.Temperature_celsius_2:before {
  content: "\e9c2";
}
.abb-icon.Temperature_fahrenheit_1:before {
  content: "\e9c3";
}
.abb-icon.Temperature_fahrenheit_2:before {
  content: "\e9c4";
}
.abb-icon.Template:before {
  content: "\e9c5";
}
.abb-icon.Template_new:before {
  content: "\e9c6";
}
.abb-icon.Test:before {
  content: "\e9c7";
}
.abb-icon.Text:before {
  content: "\e9c8";
}
.abb-icon.Time:before {
  content: "\e9c9";
}
.abb-icon.Time_and_date:before {
  content: "\e9ca";
}
.abb-icon.Track_1:before {
  content: "\e9cb";
}
.abb-icon.Track_2:before {
  content: "\e9cc";
}
.abb-icon.Transformer:before {
  content: "\e9cd";
}
.abb-icon.Trash:before {
  content: "\e9ce";
}
.abb-icon.Tree_view:before {
  content: "\e9cf";
}
.abb-icon.Trend_1:before {
  content: "\e9d0";
}
.abb-icon.Trend_2:before {
  content: "\e9d1";
}
.abb-icon.Turbocharger:before {
  content: "\e9d2";
}
.abb-icon.Undo:before {
  content: "\e9d3";
}
.abb-icon.Unpin:before {
  content: "\e9d4";
}
.abb-icon.Up:before {
  content: "\e9d5";
}
.abb-icon.Up_arrow:before {
  content: "\e9d6";
}
.abb-icon.Upload:before {
  content: "\e9d7";
}
.abb-icon.Usb:before {
  content: "\e9d8";
}
.abb-icon.User:before {
  content: "\e9d9";
}
.abb-icon.User_in_circle:before {
  content: "\e9da";
}
.abb-icon.Video_camera_off:before {
  content: "\e9db";
}
.abb-icon.Video_camera_on:before {
  content: "\e9dc";
}
.abb-icon.Video_chat:before {
  content: "\e9dd";
}
.abb-icon.Voltage:before {
  content: "\e9df";
}
.abb-icon.Warning_circle_1:before {
  content: "\e9e0";
}
.abb-icon.Warning_circle_2:before {
  content: "\e9e1";
}
.abb-icon.Warning_triangle:before {
  content: "\e9e2";
}
.abb-icon.Wrench:before {
  content: "\e9e3";
}
.abb-icon.Zoom_in:before {
  content: "\e9e4";
}
.abb-icon.Zoom_out:before {
  content: "\e9e5";
}
.abb-icon.Trend_3:before {
  content: "\e9e6";
}
.abb-icon.Trend_scatter:before {
  content: "\e9e7";
}
.abb-icon.Sort_ascending:before {
  content: "\e9e8";
}
.abb-icon.Sort_descending:before {
  content: "\e9e9";
}
.abb-icon.Advanced_settings:before {
  content: "\e9ea";
}
.abb-icon.Align:before {
  content: "\e9eb";
}
.abb-icon.Robot_axis:before {
  content: "\e9ec";
}
.abb-icon.Calibration:before {
  content: "\e9ed";
}
.abb-icon.Connection:before {
  content: "\e9ee";
}
.abb-icon.Network_status:before {
  content: "\e9ef";
}
.abb-icon.Create_backup:before {
  content: "\e9f0";
}
.abb-icon.Data_editor:before {
  content: "\e9f1";
}
.abb-icon.Execution_32:before {
  content: "\e9f2";
}
.abb-icon.Hardware_devices:before {
  content: "\e9f3";
}
.abb-icon.Io_devices:before {
  content: "\e9f4";
}
.abb-icon.Joystick:before {
  content: "\e9f5";
}
.abb-icon.Lead_through:before {
  content: "\e9f6";
}
.abb-icon.Jog_linear:before {
  content: "\e9f7";
}
.abb-icon.Document_excel:before {
  content: "\e9f8";
}
.abb-icon.Document_pdf:before {
  content: "\e9f9";
}
.abb-icon.Document_word:before {
  content: "\e9fa";
}
.abb-icon.Modify_rapid_position:before {
  content: "\e9fb";
}
.abb-icon.Software_module:before {
  content: "\e9fc";
}
.abb-icon.Network_status_1:before {
  content: "\e9fe";
}
.abb-icon.Network_status_2:before {
  content: "\e9ff";
}
.abb-icon.Payload:before {
  content: "\ea00";
}
.abb-icon.Jog_planar:before {
  content: "\ea01";
}
.abb-icon.Software_program_and_modules:before {
  content: "\ea02";
}
.abb-icon.Software_program_editor:before {
  content: "\ea03";
}
.abb-icon.Software_program:before {
  content: "\ea04";
}
.abb-icon.Program_pointer:before {
  content: "\ea05";
}
.abb-icon.Rapid_tasks:before {
  content: "\ea06";
}
.abb-icon.Jog_reorient:before {
  content: "\ea07";
}
.abb-icon.Reset_user_data:before {
  content: "\ea09";
}
.abb-icon.Restore_backup:before {
  content: "\ea0a";
}
.abb-icon.Select:before {
  content: "\ea0b";
}
.abb-icon.Software_routins:before {
  content: "\ea0c";
}
.abb-icon.Network_signal_strength_level_1:before {
  content: "\ea0d";
}
.abb-icon.Network_signal_strength_level_2:before {
  content: "\ea0e";
}
.abb-icon.Network_signal_strength_level_3:before {
  content: "\ea0f";
}
.abb-icon.Io_signals:before {
  content: "\ea10";
}
.abb-icon.Software_resources:before {
  content: "\ea11";
}
.abb-icon.Start_installer:before {
  content: "\ea12";
}
.abb-icon.Step_in:before {
  content: "\ea13";
}
.abb-icon.Step_out:before {
  content: "\ea14";
}
.abb-icon.Step_over:before {
  content: "\ea15";
}
.abb-icon.Touch:before {
  content: "\ea16";
}
.abb-icon.Video:before {
  content: "\ea17";
}
.abb-icon.Turn_clockwise:before {
  content: "\ea18";
}
.abb-icon.Turn_clockwise_off_target_speed:before {
  content: "\ea19";
}
.abb-icon.Turn_counter_clockwise_off_target_speed:before {
  content: "\ea1a";
}
.abb-icon.Add_drives:before {
  content: "\ea1b";
}
.abb-icon.Bearing:before {
  content: "\ea1d";
}
.abb-icon.Cloud:before {
  content: "\ea1e";
}
.abb-icon.Delete_drives:before {
  content: "\ea1f";
}
.abb-icon.Download_from_cloud:before {
  content: "\ea20";
}
.abb-icon.Drive_connected:before {
  content: "\ea21";
}
.abb-icon.Drives_error:before {
  content: "\ea22";
}
.abb-icon.Fieldbus:before {
  content: "\ea23";
}
.abb-icon.Generator:before {
  content: "\ea24";
}
.abb-icon.Maximum_value:before {
  content: "\ea26";
}
.abb-icon.Minimum_value:before {
  content: "\ea27";
}
.abb-icon.Not_allowed:before {
  content: "\ea28";
}
.abb-icon.Powertrain:before {
  content: "\ea29";
}
.abb-icon.Prevent_turning_fault:before {
  content: "\ea2a";
}
.abb-icon.Target_value_range:before {
  content: "\ea2b";
}
.abb-icon.Thumbnail_view:before {
  content: "\ea2c";
}
.abb-icon.Train:before {
  content: "\ea2d";
}
.abb-icon.Turn_counter_clockwise:before {
  content: "\ea2e";
}
.abb-icon.Wheel:before {
  content: "\ea2f";
}
.abb-icon.Wifi_network_disconnected:before {
  content: "\ea31";
}
.abb-icon.Write_to_default:before {
  content: "\ea32";
}
.abb-icon.Write_to_default_disabled:before {
  content: "\ea33";
}
.abb-icon.Caret_down:before {
  content: "\ea08";
}
.abb-icon.Caret_left:before {
  content: "\ea1c";
}
.abb-icon.Caret_right:before {
  content: "\ea30";
}
.abb-icon.Caret_up:before {
  content: "\ea34";
}
.abb-icon.Close_light:before {
  content: "\ea35";
}
.abb-icon.Triangle_down:before {
  content: "\ea36";
}
.abb-icon.Triangle_left:before {
  content: "\ea37";
}
.abb-icon.Triangle_right:before {
  content: "\ea38";
}
.abb-icon.Triangle_up:before {
  content: "\ea39";
}
.abb-icon.Check_mark_light:before {
  content: "\ea3a";
}
.abb-icon.Numbered_list:before {
  content: "\ea3b";
}
.abb-icon.Text_bold:before {
  content: "\ea3c";
}
.abb-icon.Text_italic:before {
  content: "\ea3d";
}
.abb-icon.Text_underline:before {
  content: "\ea3e";
}
.abb-icon.Call_outline:before {
  content: "\e9fd";
}
.abb-icon.Location_outline:before {
  content: "\ea25";
}
.abb-icon.User_outline:before {
  content: "\ea3f";
}
.abb-icon.View:before {
  content: "\e9de";
}
.abb-icon.Landscape:before {
  content: "\e9b0";
}
