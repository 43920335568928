@mixin spinner-circle($size, $color) {
  position: relative;
  display: inline-block;
  width: $size;
  height: $size;
  transform-origin: center;
  // -webkit-animation: rotation 0.75s infinite linear;
  // animation: rotation 0.75s infinite linear;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  svg > circle {
    fill: none;
    stroke: $color;
    stroke-dasharray: 215;
    stroke-width: 10;
  }

  @-webkit-keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
