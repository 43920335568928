@import "./_colors.scss";
@import "../../../style-variables.scss";
@import "../../../typography";

property-sheet {
  .mat-mdc-dialog-title {
    margin: 0;
    font-family: "ABB Font";
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .mat-mdc-dialog-actions {
    text-align: right;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 0;
  }

  .mat-mdc-dialog-content {
    margin: 0 !important;
    padding: 0 !important;
    overflow: unset;
  }
}

.property-sheet__body .mdc-tab__text-label {
  font-weight: bold;
  font-size: $section-title-font-size;
}

.property-sheet-item__body input {
  border: 0;
  border-bottom: 1px solid $light-gray-3;
}

.property-sheet-item__body input:focus {
  border-bottom: 2px solid $blue;
}
