@import "../../../typography";
@font-face {
  font-family: "dashboard-icons";
  src: url("../fonts/dashboard-icons/dashboard-icons.eot?7bvlog");
  src: url("../fonts/dashboard-icons/dashboard-icons.eot?7bvlog#iefix") format("embedded-opentype"),
    url("../fonts/dashboard-icons/dashboard-icons.ttf?7bvlog") format("truetype"),
    url("../fonts/dashboard-icons/dashboard-icons.woff?7bvlog") format("woff"),
    url("../fonts/dashboard-icons/dashboard-icons.svg?7bvlog#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

.dashboard-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "dashboard-icons" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: $icon-size-s;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dashboard-icon--medium {
  font-size: $icon-size-m;
}

.dashboard-icon.icon-Refresh-2:before {
  content: "\e92d";
}
.dashboard-icon.icon-Current-Year:before {
  content: "\e92e";
}
.dashboard-icon.icon-Previous-Year:before {
  content: "\e92f";
}
.dashboard-icon.icon-Current-Month:before {
  content: "\e930";
}
.dashboard-icon.icon-Previous-Month:before {
  content: "\e931";
}
.dashboard-icon.icon-Reset-Calendar:before {
  content: "\e932";
}
.dashboard-icon.icon-Clock:before {
  content: "\e933";
}
.dashboard-icon.icon-Calendar:before {
  content: "\e92c";
}
.dashboard-icon.icon-Bullet-chart:before {
  content: "\e926";
}
.dashboard-icon.icon-Horizontal-Gauge:before {
  content: "\e928";
}
.dashboard-icon.icon-Pollar-Chart:before {
  content: "\e929";
}
.dashboard-icon.icon-Reset-position:before {
  content: "\e92a";
}
.dashboard-icon.icon-Vertical-Gauge:before {
  content: "\e92b";
}
.dashboard-icon.icon-Undo:before {
  content: "\e925";
}
.dashboard-icon.icon-Refresh:before {
  content: "\e927";
}
.dashboard-icon.icon-Stacked-Bar-Chart:before {
  content: "\e91b";
}
.dashboard-icon.icon-Snap-to-grid:before {
  content: "\e924";
}
.dashboard-icon.icon-Arrow-single-value:before {
  content: "\e900";
}
.dashboard-icon.icon-Bar-chart:before {
  content: "\e901";
}
.dashboard-icon.icon-Basic-Card:before {
  content: "\e902";
}
.dashboard-icon.icon-Boolean:before {
  content: "\e903";
}
.dashboard-icon.icon-Circle-single-value:before {
  content: "\e904";
}
.dashboard-icon.icon-Classified-Heatmap-Chart:before {
  content: "\e905";
}
.dashboard-icon.icon-Color-Single-Value:before {
  content: "\e906";
}
.dashboard-icon.icon-Container:before {
  content: "\e907";
}
.dashboard-icon.icon-Decimal:before {
  content: "\e908";
}
.dashboard-icon.icon-Dial-Gauge:before {
  content: "\e909";
}
.dashboard-icon.icon-Drag-dots:before {
  content: "\e90a";
}
.dashboard-icon.icon-Equipment:before {
  content: "\e90b";
}
.dashboard-icon.icon-Gauge:before {
  content: "\e90c";
}
.dashboard-icon.icon-Horizontal-bar-chart:before {
  content: "\e90d";
}
.dashboard-icon.icon-Integer:before {
  content: "\e90e";
}
.dashboard-icon.icon-KM-Trend:before {
  content: "\e90f";
}
.dashboard-icon.icon-Other-Charts:before {
  content: "\e910";
}
.dashboard-icon.icon-Other-Components:before {
  content: "\e911";
}
.dashboard-icon.icon-Pie-Chart:before {
  content: "\e912";
}
.dashboard-icon.icon-Pin:before {
  content: "\e913";
}
.dashboard-icon.icon-Plain-Single-value:before {
  content: "\e914";
}
.dashboard-icon.icon-Save:before {
  content: "\e915";
}
.dashboard-icon.icon-Search:before {
  content: "\e916";
}
.dashboard-icon.icon-Settings:before {
  content: "\e917";
}
.dashboard-icon.icon-Signals:before {
  content: "\e918";
}
.dashboard-icon.icon-Single-Value:before {
  content: "\e919";
}
.dashboard-icon.icon-Solid-Gauge:before {
  content: "\e91a";
}
.dashboard-icon.icon-Stopwatch-single-value:before {
  content: "\e91c";
}
.dashboard-icon.icon-System-Overview:before {
  content: "\e91d";
}
.dashboard-icon.icon-Tab-Group:before {
  content: "\e91e";
}
.dashboard-icon.icon-Tab-links:before {
  content: "\e91f";
}
.dashboard-icon.icon-Table:before {
  content: "\e920";
}
.dashboard-icon.icon-Tables:before {
  content: "\e921";
}
.dashboard-icon.icon-Text:before {
  content: "\e922";
}
.dashboard-icon.icon-Trend:before {
  content: "\e923";
}
.dashboard-icon.icon-Button-1:before {
  content: "\e934";
}
.dashboard-icon.icon-Button-2:before {
  content: "\e935";
}
.dashboard-icon.icon-Button-3:before {
  content: "\e936";
}
.dashboard-icon.icon-Auto-Position-1:before {
  content: "\e937";
}
.dashboard-icon.icon-Auto-Position-2:before {
  content: "\e938";
}
.dashboard-icon.icon-Auto-Position-3:before {
  content: "\e939";
}
.dashboard-icon.icon-Auto-Position-4:before {
  content: "\e93a";
}
