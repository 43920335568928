$ui-blue-primary: #3366ff;
// BLUE
$ui-blue-10: #e0f0ff;
$ui-blue-20: #c5e0fa;
$ui-blue-30: #9ebff7;
$ui-blue-40: #77a3fc;
$ui-blue-50: #4c85ff;
$ui-blue-70: #2a35ff;
$ui-blue-80: #2c20d4;
$ui-blue-90: #160c9c;
$ui-blue-100: #080261;

// GREY
$ui-grey-10: #ebebeb;
$ui-grey-20: #dbdbdb;
$ui-grey-30: #bababa;
$ui-grey-40: #9f9f9f;
$ui-grey-50: #848484;
$ui-grey-60: #686868;
$ui-grey-70: #525252;
$ui-grey-80: #3d3d3d;
$ui-grey-90: #1f1f1f;
$ui-grey-100: #0f0f0f;
$ui-grey-100-opacity-3: rgba(0, 0, 0, 0.3);

$content-area-light: #ffffff;
$content-area-dark: #2e2e2e;
$application-background-light: #ebebeb;
// $application-background-dark: #1F1F1F;
$application-background-dark: #262626;
$primary-foreground-light: #1f1f1f;
$primary-foreground-dark: #dbdbdb;
$secondary-foreground-light: #686868;
$secondary-foreground-dark: #848484;
$focus-background-light: #dbdbdb;
$focus-background-dark: #525252;
$input-border-light: #bababa;
$input-border-dark: #525252;

$red: #f03040;
$yellow: #ffd800;
$green: #0ca919;
$magenta: #cb2bd5;
