@import "./base-editor.scss";

.c-button:disabled {
  background-color: $abb-status-blue;
  color: #ffffff40;

  &:hover {
    background-color: $abb-status-blue;
    cursor: auto;
  }
}

.c-button:focus {
  outline: none;
}

.c-button {
  background-color: var(--primary-black-button-background);
  color: var(--primary-black-button-foreground);
  border: 1px solid var(--primary-black-button-background);
  font-weight: lighter;
  width: auto;
  min-height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  margin: 4px;
  cursor: pointer;
  font-size: $label-font-size;
  @include focused-input();
  &:hover {
    background-color: var(--primary-black-button--hover);
    border-color: var(--primary-black-button--hover);
  }
  &:active {
    background-color: var(--primary-black-button--pressed);
    border-color: var(--primary-black-button--pressed);
  }
}

.c-button--rounded {
  min-width: 90px;
  border-radius: 5px;
  border: none;
}

.c-button--circle {
  width: 40px;
  border-radius: 20px;
  border: none;
  color: $abb-status-blue;
}

.c-button--light {
  background-color: white;
  border: 1px solid $light-gray-2;
  color: $abb-status-blue;
  text-transform: uppercase;
  &:hover {
    background-color: $abb-status-blue;
    color: white;
  }
}

.c-button--primary {
  color: #fff;
  background-color: $abb-status-blue;
  border: 1px solid $abb-status-blue;
  &:hover {
    background-color: $abb-ui-blue-50;
    border-color: $abb-ui-blue-50;
  }
  &:active {
    background-color: $abb-ui-blue-45;
    border-color: $abb-ui-blue-45;
  }
}

.c-button--discrete {
  border-color: transparent;
  background: transparent;
  border-width: 1px;
  &:hover {
    border: 1px solid rgb(186, 186, 186);
  }
}

.trend__button--rounded {
  min-width: 70px;
}

ul.c-button-strip {
  //  display: flex;
  // flex-direction: row;
  list-style-type: none;
  display: block;
  white-space: nowrap;
  padding: 0px;
  margin: 0px 0px 20px;
}

.c-button-strip__item {
  float: left;
  color: rgb(31, 31, 31);
  background: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color: $light-gray-3;
}

.c-button-strip__button {
  padding: 0px 10px;
  margin: 0px;
  &:hover {
    background-color: $highlighted;
  }
}

.c-button-strip__button--selected {
  background-color: $highlighted;
}

.c-button-strip__item:first-of-type {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.c-button-strip__item:last-of-type {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.app-filter-button {
  background-color: transparent;
  color: var(--foreground-primary);
  border: none;
  height: 100%;
  cursor: pointer;
  &:focus {
    outline: none;
  }

  &__collapse {
    margin: 0 10px 0;
    width: 24px;
    &:hover {
      color: $abb-status-blue;
    }
  }

  &__clipboard-copy {
    margin: 0 10px 0;
    &:hover {
      color: $abb-status-blue;
    }
  }
}

.sidebar__header-button {
  display: flex;
  align-items: center;
  margin: 2px;
  background-color: transparent;
  cursor: pointer;
  color: var(--foreground-primary);
  @include focused-input();

  &--pinned {
    color: $abb-ui-blue-50;
  }
}

.rounded-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  border-radius: 30px;
  background-color: var(--primary-black-button-background);
  border: 1px solid var(--primary-black-button-background);
  color: var(--primary-black-button-foreground);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &__wrapper {
    padding: 3px;

    &:focus {
      @include focus-style();
      border-radius: 30px;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: var(--primary-black-button--hover);
    border-color: var(--primary-black-button--hover);
  }

  &:active {
    background-color: var(--primary-black-button--pressed);
    border-color: var(--primary-black-button--pressed);
  }
}
