$light-gray: #f0f0f0;
$light-gray-2: #828282;
$light-gray-3: #ccc;
$light-gray-4: #c8c8c8;
$abb-grey-2c: #2c2c2c;
$abb-grey-3c: #3c3c3c;
$gray-4c: #4c4c4c;
$gray-7c: #7c7c7c;
$gray-8c: #8c8c8c;
$gray-ba: #bababa;
$gray-db: #dbdbdb;
$gray-c8: #c8c8c8;
$gray-6f: #6f6e6e;
$gray-ec: #ececec;
$gray-f3: #f3f3f3;
$gray-26: #262626;
$blue: #2a35ff;
$blue-00: #0096e0;
$white: #fff;
$white-dc: #dcdcdc;
$black: #000000;
$abb-white-F5: #f5f5f5;
$red-ff: #ff000f;

$abb-status-yellow: #ffd800;
$abb-status-red: #f03040;
$abb-status-magenta: #cb2bd5;
$abb-status-green: #0ca919;
$abb-status-blue: #3366ff;

$abb-ui-blue-45: #6c94e6;
$abb-ui-blue-50: #4c85ff;
$abb-ui-blue-60: #3366ff;
$abb-ui-blue-60-alpha-50: #3366ff86;
$abb-ui-blue-70: #2a35ff;

$abb-grey-10: #e6e6e6;
$abb-grey-15: #d1d1d1;
$abb-grey-20: #c4c4c4;
$abb-grey-25-alpha-50: #b8b8b886;
$abb-grey-30: #ababab;
$abb-grey-40: #949494;

$highlighted: $abb-ui-blue-60;
