.dynamic-buttons-container {
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  top: 5px;
  font-size: 15px;
  right: 5px;
  z-index: 112; // NOTE index bigger than positioning-service__drag-overlay
}

.dynamic-button {
  justify-self: flex-end;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  padding: 6px;
  margin-right: 3px;
  font-size: 17px;
  z-index: 100;
}

.dynamic-button--config {
  @extend .dynamic-button;
  background-color: $abb-ui-blue-60;
  color: white;
  &:hover {
    background-color: $abb-ui-blue-70;
  }
}

.dynamic-button--runtime {
  @extend .dynamic-button;
  background-color: transparent;
  color: black;
  &:hover {
    border: 1px solid $light-gray-3;
    padding: 5px;
  }
}
