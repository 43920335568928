@import "../../../assets/ui-core/css/_colors.scss";
@import "../css/themes.scss";
@import "../../../style-variables.scss";
@import "../../../assets/ui-core/css/colors";
@import "../../../typography";
@import "../css/base-editor.scss";

$font-sizes: 9px, 11px, 13px, 16px, 19px, 21px;

/* SLIDER Rules */
.mat-mdc-slider.mdc-slider {
  width: 100% !important;
  min-width: unset !important;
}

.mat-mdc-slider-visual-thumb .mat-ripple {
  visibility: hidden;
}

.mat-mdc-slider .mdc-slider__thumb-knob {
  border-color: $white !important;
  margin-top: 1px;
}

.mat-mdc-slider .mdc-slider__track--active_fill {
  border-color: var(--status-blue-color) !important;
}

.mat-mdc-slider .mdc-slider__track--inactive {
  background-color: var(--input-background-secondary) !important;
  opacity: 1 !important;
}

.mat-slider-thumb-label {
  background-color: #d2d2d2 !important;
  transform: rotate(45deg) !important;
  border-radius: 50% 50% 0 !important;
}

.mat-accent .mat-slider-track-fill {
  background-color: #828282;
}

.mat-slider-thumb {
  transform: scale(0) !important;
}

.mat-mdc-slide-toggle .mat-slide-toggle-ripple {
  display: none !important;
}

.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #3366ff !important;
}

.mat-mdc-slide-toggle.mat-checked .mat-mdc-slide-toggle-thumb {
  background-color: #fafafa !important;
}

.mat-accent .mat-slider-track-fill {
  background-color: #3366ff !important;
}

.mat-slide-toggle-content {
  font-size: $icon-size-s !important;
  font-family: "ABB Font" !important;
}

/* CHECKBOX Rules */

.mat-pseudo-checkbox-checked {
  background: #ffffff !important;
}

.mat-pseudo-checkbox {
  border: 1px solid #c3c3c3 !important;
  height: 15px !important;
  width: 15px !important;
  padding: 0;
}

.mat-pseudo-checkbox::after {
  left: 4px !important;
  top: 0px !important;
  width: 3.5px !important;
  height: 8px !important;
  border: solid #12b0ff !important;
  border-width: 0 2px 2px 0 !important;
  -webkit-transform: rotate(45deg) !important;
  -ms-transform: rotate(45deg) !important;
  transform: rotate(45deg) !important;
}

/* Vertical toolbar */
.mat-drawer-inner-container {
  display: flex;
}

.mat-mdc-tab-group[verticalToolbar] .mat-mdc-tab-labels {
  display: flex;
  flex-direction: column !important;
  width: 48px;
}

.mat-mdc-tab-group[verticalToolbar] .mdc-tab__content {
  width: 48px;
  min-width: 48px;
  max-width: 48px;
}

.mat-mdc-tab-group[verticalToolbar] {
  display: flex;
  flex-direction: row !important;
  flex: 1 1 auto;
  box-sizing: border-box;
}

.mat-mdc-tab-group[verticalToolbar] .mat-mdc-tab-body-wrapper {
  box-sizing: border-box;
}

.mat-mdc-tab-group[verticalToolbar] .mat-mdc-tab-body-content {
  background-color: $abb-grey-3c;
  box-sizing: border-box;
}

.mat-mdc-tab-group[verticalToolbar] .mat-mdc-tab-header {
  background-color: $abb-grey-2c;
  width: 48px;
  box-sizing: border-box;
}

.mat-mdc-tab-group[verticalToolbar] .mat-mdc-tab-header-pagination {
  border-bottom: none;
  display: none !important;
}

.mat-mdc-tab-group[verticalToolbar] .mat-ink-bar {
  border-bottom: none;
  display: none !important;
}

.mat-mdc-tab-group[verticalToolbar] {
  font-family: "ABB Font";
}

.sidebar__tabsComponentWrapper {
  overflow: hidden;
}

.sidebar__tabsComponentWrapper .mat-mdc-tab-list {
  transform: none !important;
}

/* Material paginator */

.table__paginator {
  .mat-mdc-input-element {
    color: $abb-grey-3c !important;
  }

  .mat-mdc-form-field-flex {
    padding-top: 0px;
  }

  .mat-mdc-select-value {
    font-size: $font-size-10 !important;
  }

  .mat-mdc-paginator-container {
    min-height: 40px !important;
    justify-content: space-between !important;
    padding: 2px 8px !important;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    padding: 5px !important;
    height: 35px !important;
    width: 29px !important;
  }

  .mat-mdc-paginator-range-label {
    margin: 0 !important;
    font-size: $font-size-10 !important;
  }

  .mat-mdc-paginator-page-size-label {
    font-size: $font-size-10 !important;
    margin: 0px !important;
  }

  .mat-mdc-paginator-page-size-select {
    width: 50px !important;
  }

  .mat-mdc-text-field-wrapper {
    height: 30px !important;
    align-items: center !important;
    padding: 5px !important;
  }

  .mat-mdc-paginator-page-size {
    align-items: center !important;
    margin-right: 0 !important;
  }
}

/* Property sheet */

.property-sheet__body .mat-mdc-tab-body-wrapper {
  flex: 1;
}

.property-sheet__body .mat-mdc-tab-body-content {
  overflow-x: hidden;
}

.property-sheet__body .mat-mdc-tab-group {
  width: 100%;
}

.property-sheet__body .mat-mdc-tab-header {
  max-width: 90%;
  border-bottom: unset;
}

.property-sheet__body .mat-mdc-tab:hover {
  .mdc-tab__ripple::before {
    background-color: var(--background-secondary--active);
    opacity: 1;
  }
}

.property-sheet__body .mdc-tab--active .mdc-tab__ripple {
  display: none;
}

.property-sheet__body {
  .mdc-tab-indicator {
    top: -100%;
  }

  .mat-mdc-tab {
    border-top: 1px solid $abb-grey-40;
    min-width: unset;
    padding-left: unset;
    padding-right: unset;
    &[aria-labelledby="hidden"] {
      display: none;
    }

    .mdc-tab__text-label {
      color: var(--foreground-primary) !important;
    }
  }
}

.property-sheet__body .mat-ink-bar {
  top: 0px;
  background-color: var(--status-blue-color) !important;
  height: 2px;
  transition: none;
}

.property-sheet__body .property-sheet__search-tab-label {
  border-top: none;

  .mdc-tab__content {
    width: 100%;
  }

  .mdc-tab-indicator {
    display: none;
  }
}

.search-input .mat-ink-bar {
  display: none !important;
}

.search-input .mdc-tab__content {
  border-top: unset;
  padding: 10px !important;

  .mdc-tab__text-label {
    width: 100%;
  }
}

/* Filter Sidebar */

.filter-sidebar__body .mat-ink-bar {
  display: none;
}

.filter-sidebar__body .mat-mdc-tab-group {
  height: 100%;
}

.filter-sidebar__body .mdc-tab__content {
  cursor: auto;
}

/* Report Popup */

.custom-mat-dialog-panel .mat-mdc-dialog-container {
  width: 80vw;
}

.custom-mat-dialog-panel .mat-mdc-dialog-surface {
  resize: both;
}

.custom-mat-dialog-panel .mat-mdc-dialog-title {
  padding: 0px;
}

/* Material dialogs global rules */

.mat-mdc-dialog-container {
  .mat-mdc-dialog-surface {
    padding: 24px;
    align-self: center;
    background-color: var(--background-quaternary) !important;
    border-radius: 4px;
  }

  .mat-mdc-floating-label,
  .mat-mdc-dialog-title {
    color: var(--foreground-primary) !important;
    font-size: $title-font-size !important;
  }

  .mat-mdc-form-field-focus-overlay {
    display: none;
  }
}

/* Color Picker Popup */

.editor-overlay-dialog-panel .mat-mdc-dialog-surface {
  padding: 0px !important;
  overflow: hidden;
  background-color: white !important;
}

/* Progress bar */

.equipment-tree-progress-bar .mdc-linear-progress__buffer,
.signal-browser__progress-bar .mdc-linear-progress__buffer {
  background: #262626;
}

.app-header-button__progress-bar .mdc-linear-progress__buffer,
.dark_report-loader .mdc-linear-progress__buffer {
  background: #1f1f1f;
}

.light_report-loader .mdc-linear-progress__buffer {
  background: #f0f0f0;
}

.lightTheme .equipment-tree-progress-bar .mdc-linear-progress__buffer,
.lightTheme .signal-browser__progress-bar .mdc-linear-progress__buffer {
  background: white;
}

.mdc-linear-progress__bar-inner {
  border-color: #3366ff !important;
}

/* SnackBar */

.snack-bar-notification {
  color: var(--foreground-primary) !important;

  .mdc-snackbar__surface {
    background-color: var(--background-quaternary) !important;
    border-top: 4px solid var(--background-quaternary);
    .mat-mdc-snack-bar-label {
      color: var(--foreground-primary) !important;
    }
  }
  .mat-mdc-snack-bar-action {
    max-height: 36px;
    min-width: 0;
    color: var(--foreground-primary) !important;

    &:hover {
      background-color: var(--background-quaternary--active) !important;
    }
  }
}

.snack-bar-notification--error .mdc-snackbar__surface {
  border-top-color: #ef2f40;
}

.snack-bar-notification--warning .mdc-snackbar__surface {
  border-top-color: #ffd700;
}

/* Datetime picker */

.mat-calendar-header {
  color: $black;
  height: 45px;
  display: flex;
  justify-content: center;
  padding: 0 !important;
}

.mat-calendar-body-label {
  visibility: hidden;
  padding: 0 !important;
}

.mat-calendar-body-cell-content {
  margin: 5px;
  height: 70%;
  width: 70%;
  left: 0 !important;
  font-size: $mat-calendar-content-size !important;
}

.mat-calendar-content {
  padding: 0 8px !important;
}

.mat-calendar-body-selected {
  background-color: var(--status-blue-color) !important;
  color: $white !important;
}

.mat-form-field-label {
  color: var(--foreground-secondary) !important;
}

.mat-mdc-input-element {
  color: #c8c8c8 !important;
}

.mat-datepicker-toggle {
  color: #c8c8c8 !important;
}

.mat-form-field-underline {
  background-color: var(--foreground-primary) !important;
}

.invalid-input-data .mat-form-field-underline {
  background-color: red !important;
}

/* Time picker */

.ngx-mat-timepicker-meridian {
  position: relative;
  & .mat-mdc-button {
    height: 32px !important;
    border-radius: 4px !important;
    margin-left: 5px !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.ngx-mat-timepicker-table {
  .mat-mdc-input-element {
    color: $black !important;
  }

  & .mat-mdc-icon-button .mat-icon {
    color: $black !important;
    opacity: 0.54;
  }

  & tr {
    display: flex;
    flex-direction: row;
    height: 35px;

    & td {
      min-width: 32px;
      max-width: 35px;
      justify-content: center;
      display: flex;

      &:nth-child(even) {
        display: none;
      }

      & .mat-mdc-form-field {
        height: 25px !important;
      }
    }

    &:first-child {
      align-items: end;

      & td {
        margin-right: 2px;
      }

      & td .mat-mdc-icon-button {
        display: flex;
        align-items: end;
        height: 100%;
        justify-content: center;
      }
    }

    &:last-child {
      align-items: center;

      & td {
        margin-right: 2px;

        & .mat-mdc-icon-button {
          display: flex;
          height: 100%;
        }
      }
    }
  }
}

.ngx-mat-timepicker-spacer {
  position: relative;
  color: $black !important;
  opacity: 0.54;
  max-width: unset !important;
  min-width: unset !important;
  width: 2px !important;
  height: 25px;
  align-items: end;
  display: flex !important;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.basic-card__body .mat-mdc-tab-group,
.basic-card__body .mat-mdc-tab-body-wrapper {
  flex: 1 1 auto;
}

.app-header__add-report-select .mat-mdc-select-arrow-wrapper {
  display: none;
}

.equipment-class-multi-select .mat-mdc-text-field-wrapper,
.custom-select-editor__wrapper .mat-mdc-text-field-wrapper {
  padding-bottom: 0px !important;
}

.equipment-class-multi-select .mat-mdc-form-field-infix,
.custom-select-editor__wrapper .mat-mdc-form-field-infix {
  padding: 0px !important;
  border-top-width: 0px !important;
}

// FIXME If color picker isn't Angular Material, these rules should be moved to styles.scss
/* Color-picker */

.color-picker__cancel-button {
  transform: translateX(100%) !important;
}

.color-picker__ok-button {
  transform: translateX(-100%) !important;
}

.color-picker__ok-button,
.color-picker__cancel-button {
  width: 80px;
  margin-left: 0px !important;
}

.color-picker .button-area {
  text-align: center !important;
}

// material tabs for TabGroupComponent
.tab-group-wrapper {
  .mat-mdc-tab-group {
    width: 100%;
  }

  .mat-mdc-tab-header {
    border-bottom: 1px solid $light-gray;

    .mdc-tab__text-label {
      color: black !important;
    }
  }

  .mat-mdc-tab-body-content {
    display: flex;
    flex: 1 1 auto;
  }

  .mat-mdc-tab-body {
    display: flex;
    flex: 1 1 auto;
  }

  .mat-mdc-tab-body-wrapper {
    display: flex;
    flex: 1 1 auto;
  }

  .mat-ink-bar {
    background-color: $abb-ui-blue-60 !important;
    transition: none;
  }
}

.edit-mode--active {
  .tab-group-wrapper {
    .mat-mdc-tab-header-pagination-after {
      margin-right: 80px;
    }
  }
}

// drop-down in app-header
.app-header__drop-down .mat-mdc-select-arrow-wrapper {
  display: none !important;
}

// dial-gauge data-label
c-single-value .highcharts-label.highcharts-data-label {
  opacity: 1 !important;
}

//autocomplete
.mat-mdc-autocomplete-panel.mat-mdc-autocomplete-visible {
  position: absolute !important;
}

.mat-mdc-autocomplete-panel {
  background-color: var(--input-background-primary) !important;

  .mat-mdc-option {
    font-size: $font-size-12 !important;
    min-height: unset;

    .mdc-list-item__primary-text {
      color: var(--foreground-primary) !important;
    }
  }

  .mdc-list-item--selected {
    background-color: var(--status-blue-color) !important;

    .mat-pseudo-checkbox {
      display: none;
    }
  }
}

body {
  --mdc-typography-button-letter-spacing: "normal";
  --mdc-theme-primary: var(--status-blue-color);
  --mdc-tab-indicator-active-indicator-color: var(--status-blue-color);
}

/* Table */
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  border-bottom-style: none !important;
}

.mat-mdc-tab-link.mat-mdc-tab-disabled {
  opacity: unset !important;
}

.ql-align-right {
  text-align: right;
}

.ql-align-center {
  text-align: center;
}

.ql-align-left {
  text-align: left;
}

.ql-align-justify {
  text-align: justify;
}

.ql-snow .ql-picker.ql-size {
  & .ql-picker-label::before,
  & .ql-picker-item::before {
    content: "Font size";
  }

  @each $size in $font-sizes {
    & .ql-picker-item[data-value="#{$size}"]::before {
      content: "#{$size}";
      font-size: #{$size} !important;
    }
  }
}

.mat-mdc-tab-link .mdc-tab__text-label {
  color: unset !important;
}

.mat-mdc-tab .mdc-tab__text-label {
  letter-spacing: normal;
}
