@import "flatpickr/dist/themes/dark.css";

.flatpickr-input {
  height: 30px;
  width: 100%;
  background-color: #262626;
  font-size: 15px;
  font-family: "ABB Font";
  color: #c8c8c8;
  border: none;
  cursor: default !important;
}

.flatpickr-input:focus {
  border: none;
}

.flatpickr-day.selected,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:active,
.flatpickr-day.selected:hover {
  background: #3366ff;
}
.flatpickr-calendar {
  background: #262626;
  width: 263px;
}
.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #262626;
  border: 2px solid #5a5a5a;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #3366ff;
}

input:focus {
  outline: none !important;
}
