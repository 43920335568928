/* The container */

.c-checkbox__wrapper {
  display: block;
  position: relative;
  margin: 0 2px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 16px;
  width: 100%;
  padding: 0;
  text-align: center;
}

/* Hide the browser's default checkbox */

.c-checkbox__wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  display: none;
}

/* Create a custom checkbox */

.c-checkbox-checkmark {
  position: absolute;
  top: 0;
  left: calc(50% - 7px);
  height: 13px;
  width: 13px;
  background-color: #ffffff;
  border: 1px solid #c3c3c3;
}

.c-checkbox-checkmark__read-only {
  background-color: #f0f0f0;
}

/* On mouse-over, add a grey background color */

.c-checkbox__wrapper:hover input ~ .c-checkbox-checkmark {
  background-color: #f0f0f0;
}

/* Create the c-checkbox-checkmark/indicator (hidden when not checked) */

.c-checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.c-checkbox__wrapper input:checked ~ .c-checkbox-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.c-checkbox__wrapper .c-checkbox-checkmark:after {
  left: 4px;
  top: 0px;
  width: 3.5px;
  height: 8px;
  border: solid #12b0ff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* CHECKBOX Rules END */
